import { isNil } from 'ramda';
import ELEMENTS from '~/components/organism/PluginsEditor/components/elements/elementsEnum';
import type { EditorValue } from '~/components/organism/PluginsEditor/types';
import getVariablesAsMappings from '~/components/organism/PluginsEditor/utils/flows/getVariablesAsMappings';
import serializeAllElements from '~/components/organism/PluginsEditor/utils/serialize';
import serializeAsPlainText from '~/components/organism/PluginsEditor/utils/serializeAsPlainText';
import { FlowAction, FlowV2_TemplateStringFragment } from '~/graphql/types';
import { ClientFlowAction } from '~/graphql/types.client';
import { CUSTOM_HTML_PRAGMA } from '../../components/SendEmailPlain/constants';

/**
 * Convert slate values into template &amp; mappings in actions that use the DHEditor
 * @param {ClientFlowAction} changes -Changes made in the action
 * keywords:
 */
const serializeEditorValues = (changes: ClientFlowAction): ClientFlowAction => {
  switch (changes.actionType) {
    case FlowAction.SendEmailPlain: {
      const { bodyValue, subjectValue, body, subject, customHtml, ...rest } =
        changes;
      let updatedSubject = subject;
      let updatedBody = body;

      if (subjectValue) {
        updatedSubject = serializeSimpleField(subjectValue);
      }

      if (bodyValue) {
        updatedBody = {
          __typename: 'FlowV2_TemplateString',
          template: serializeAllElements({
            fragment: bodyValue,
            customElements: [
              ELEMENTS.VARIABLE,
              ELEMENTS.DH_IMAGE,
              ELEMENTS.IMAGE,
              ELEMENTS.DEEP_LINK,
            ],
          }),
          mappings: getVariablesAsMappings(bodyValue),
        };
      }

      if (!isNil(customHtml)) {
        const customHtmlWithPrefix = customHtml.startsWith(CUSTOM_HTML_PRAGMA)
          ? customHtml
          : `${CUSTOM_HTML_PRAGMA}${customHtml}`;

        updatedBody = {
          __typename: 'FlowV2_TemplateString',
          template: customHtmlWithPrefix,
          mappings: [],
        };
      }

      return {
        ...rest,
        subject: updatedSubject,
        body: updatedBody,
      };
    }

    case FlowAction.SendNotification: {
      const { bodyValue, subjectValue, toOptions, body, subject, ...rest } =
        changes;

      let updatedSubject = subject;
      let updatedBody = body;

      if (subjectValue) {
        updatedSubject = serializeSimpleField(subjectValue);
      }

      if (bodyValue) {
        updatedBody = {
          __typename: 'FlowV2_TemplateString',
          template: serializeAllElements({
            fragment: bodyValue,
            customElements: [ELEMENTS.VARIABLE, ELEMENTS.DH_IMAGE],
          }),
          mappings: getVariablesAsMappings(bodyValue),
        };
      }

      const updatedTargets = toOptions
        ? toOptions.map(option => option.payload.value)
        : [];

      return {
        ...rest,
        to: updatedTargets,
        subject: updatedSubject,
        body: updatedBody,
      };
    }

    case FlowAction.ContactDetails: {
      const { nameValue, phoneValue, name, phone, ...rest } = changes.field;

      let updatedName = name;
      if (nameValue) {
        updatedName = serializeSimpleField(nameValue);
      }

      let updatedPhone = phone;
      if (phoneValue) {
        updatedPhone = serializeSimpleField(phoneValue);
      }

      return {
        ...changes,
        field: { ...rest, name: updatedName, phone: updatedPhone },
      };
    }

    case FlowAction.TaskCreate: {
      const { titleValue, descriptionValue, title, description, ...rest } =
        changes;

      let updatedTitle = title;
      let updatedDescription = description;

      if (titleValue) {
        updatedTitle = serializeSimpleField(titleValue);
      }

      if (descriptionValue) {
        updatedDescription = {
          __typename: 'FlowV2_TemplateString',
          template: serializeAllElements({
            fragment: descriptionValue,
            customElements: [ELEMENTS.VARIABLE],
          }),
          mappings: getVariablesAsMappings(descriptionValue),
        };
      }

      return {
        ...rest,
        title: updatedTitle,
        description: updatedDescription,
      };
    }

    case FlowAction.RealworksSendContact: {
      const { description, descriptionValue, ...rest } = changes;

      let updatedDescription = description;
      if (descriptionValue) {
        updatedDescription = {
          __typename: 'FlowV2_TemplateString',
          template: serializeAllElements({
            fragment: descriptionValue,
            customElements: [ELEMENTS.VARIABLE],
          }),
          mappings: getVariablesAsMappings(descriptionValue),
        };
      }

      return {
        ...rest,
        description: updatedDescription,
      };
    }

    default:
      return changes;
  }
};

const serializeSimpleField = (
  value: EditorValue,
): FlowV2_TemplateStringFragment => ({
  __typename: 'FlowV2_TemplateString',
  template: serializeAsPlainText({
    fragment: value,
    customElements: [ELEMENTS.VARIABLE],
  }),
  mappings: getVariablesAsMappings(value),
});

export default serializeEditorValues;
