import reactNodeToString from '~/util/reactNodeToString';
import type { DeepLinksMap } from '../../state/deepLinks';
import Validation from '~/util/Validation';

/**
 * Passes the deep link label into the label
 * @param {React.ReactNode | string} label - Initial label
 * @param {DeepLinksMap} deepLinksMap - Initial label
 */
const getArgumentLabelForDeepLink = ({
  label,
  deepLinksMap,
}: {
  label: React.ReactNode | string;
  deepLinksMap: DeepLinksMap;
}) => {
  const labelAsStr = reactNodeToString(label);

  // Label has double quotes around it so remove it
  const id = labelAsStr.replace(/"/g, '');

  const argumentLabel = Validation.DeepLinkId.isValid(id)
    ? `"${deepLinksMap[id]?.label || ''}"`
    : label;

  return argumentLabel;
};

export default getArgumentLabelForDeepLink;
