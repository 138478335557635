import React from 'react';
import type {
  ConditionExpression,
  ConditionGroup,
  ConditionSubExpression,
  Flow___ConditionPartial,
} from '../../types';
import ConditionGroupComponent from '../../components/ConditionGroup';
import { clone, remove } from 'ramda';
import type { ConnectorOperator } from '~/graphql/types';
import type { RelativeMaps } from '~/components/page/Automation/v2/types';
import generateRelativeMaps from '~/components/page/Automation/v2/components/UpdateAction/utils/generateRelativeMaps';
import { Props as ConditionEditorProps } from '../../index';

type Args = Pick<
  ConditionEditorProps,
  | 'action'
  | 'actionId'
  | 'hasTriggers'
  | 'inputListPrimitives'
  | 'inputPrimitives'
  | 'maps'
  | 'outerContainer'
  | 'textObject'
> & {
  conditionGroups: Array<ConditionGroup>;
  expressionKey: string;
  conditionSubExpressionIdx: number;
  conditionSubExpression: Array<ConditionSubExpression>;
  conditionExpressionConnector: ConnectorOperator;
  triggerCondition: Flow___ConditionPartial | null;
  setConditionExpression: (
    nextConditionExpression: ConditionExpression,
  ) => void;
};

const getConditionGroupElements = ({
  conditionGroups,
  expressionKey,
  actionId,
  hasTriggers,
  conditionSubExpressionIdx,
  outerContainer,
  setConditionExpression,
  conditionSubExpression,
  conditionExpressionConnector,
  maps,
  action,
  triggerCondition,
  inputListPrimitives,
  inputPrimitives,
  textObject,
}: Args): Array<React.JSX.Element> =>
  conditionGroups.map(
    ({ conditions, connector: conditionGroupConnector }, conditionGroupIdx) => {
      const conditionGroupKey = `${expressionKey}-${conditionGroups.length}-${conditionGroupIdx}`;
      const isFirst = conditionGroupIdx === 0;
      const isTrigger = hasTriggers && isFirst;
      const type = isTrigger ? 'trigger' : 'condition';

      const relativeMaps = ((): RelativeMaps => {
        if (isTrigger) return maps;
        if (triggerCondition == null) return maps;

        return generateRelativeMaps({
          triggerCondition,
          actionId,
          maps,
        });
      })();

      return (
        <ConditionGroupComponent
          key={conditionGroupKey}
          conditionSubExpressionIdx={conditionSubExpressionIdx}
          conditionGroupIdx={conditionGroupIdx}
          conditions={conditions}
          connector={conditionGroupConnector}
          outerContainer={outerContainer}
          onDelete={() => {
            if (conditionGroupIdx === 0) {
              /**
               * You delete the first expression which is
               * either the trigger, or the only element.
               *
               * Remove the whole thing.
               */
              setConditionExpression({
                conditionSubExpression: remove(
                  conditionSubExpressionIdx,
                  1,
                  conditionSubExpression,
                ),
                connector: conditionExpressionConnector,
              });

              return;
            }

            const nextConditionSubExpression = [...conditionSubExpression];

            nextConditionSubExpression[
              conditionSubExpressionIdx
            ].conditionGroups = remove(
              conditionGroupIdx,
              1,
              nextConditionSubExpression[conditionSubExpressionIdx]
                .conditionGroups,
            );

            setConditionExpression({
              conditionSubExpression: nextConditionSubExpression,
              connector: conditionExpressionConnector,
            });
          }}
          onUpdate={nextConditionGroup => {
            const nextConditionSubExpression = clone(conditionSubExpression);

            nextConditionSubExpression[
              conditionSubExpressionIdx
            ].conditionGroups[conditionGroupIdx] = nextConditionGroup;

            setConditionExpression({
              conditionSubExpression: nextConditionSubExpression,
              connector: conditionExpressionConnector,
            });
          }}
          maps={relativeMaps}
          context={{ action, type }}
          inputListPrimitives={inputListPrimitives}
          inputPrimitives={inputPrimitives}
          textObject={textObject}
        />
      );
    },
  );

export default getConditionGroupElements;
