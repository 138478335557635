import React from 'react';
import JustificationContainer from '~/components/atom/JustificationContainer';
import type { LimitFragment } from '~/graphql/types';
import getPercentage from '~/util/getPercentage';
import UpgradePlanCta from '../UpgradePlanCTA';
import StrapiLink from '../StrapiLink';
import PercentageBar from '~/components/molecule/PercentageBar';
import type { SystemSize } from '~/theme';

const text = {
  header: 'Let op!',
  strapiLinkLabel: 'Leer meer over dit limiet',
};

export type Props = {
  dataTestId?: string;
  className?: string;
  textObj: {
    body: {
      approachingLimit: string;
      overLimit: string;
    };
    buttonLabel: string;
  };
  margin?: Array<SystemSize | null>;
  /**
   * The limit to check.
   */
  limit: LimitFragment;
  /**
   * The current value of the limit.
   */
  currentValue: number;
  /**
   * The percentage at which the CTA should be shown.
   * @default 80
   */
  thresholdPercentage?: number;
};

const LimitUpgradeCTA: React.FCC<Props> = ({
  dataTestId,
  textObj,
  limit,
  currentValue,
  margin = ['base', null, null, null],
  thresholdPercentage = 80,
}) => {
  const limitPercentage = getPercentage(currentValue, limit.limit);
  const approachingLimit = limitPercentage < 100;
  const overLimit = limitPercentage > 100;

  if (!(limit && limitPercentage >= thresholdPercentage)) return null;

  return (
    <UpgradePlanCta
      dataTestId={dataTestId}
      header={text.header}
      description={
        approachingLimit
          ? textObj.body.approachingLimit
          : textObj.body.overLimit
      }
      buttonLabel={textObj.buttonLabel}
      margin={margin}
    >
      <JustificationContainer margin={['xs', null, null, null]}>
        <StrapiLink
          label={text.strapiLinkLabel}
          reason={limit.reason}
          color="white"
        />
      </JustificationContainer>
      <JustificationContainer margin={['base', null, null, null]} width="100%">
        <PercentageBar
          currentValue={currentValue}
          total={limit.limit}
          color={overLimit ? 'danger' : 'white'}
          visiblePercentage
        />
      </JustificationContainer>
    </UpgradePlanCta>
  );
};

export default LimitUpgradeCTA;
