import { ApolloQueryResult } from '@apollo/client';
import type { RouteComponentProps } from '@gatsbyjs/reach-router';
import { navigate } from '@gatsbyjs/reach-router';
import React, { useEffect } from 'react';
import { animated, useTransition } from 'react-spring';
import DHRouter from '~/components/atom/DHRouter';
import Button from '~/components/atom/Button';
import Divider from '~/components/atom/Divider';
import { Body } from '~/components/atom/Typography';
import {
  AppStatusRealworksFieldsFragment,
  Exact,
  GetAppStatusRealworksQuery,
} from '~/graphql/types';
import AppDetailsContainer from '~/components/page/Apps/components/AppDetailsContainer';
import AddTokenContainerWizard from './components/AddTokenContainerWizard';
import TokenContainer from './components/TokenContainer';

import TEST_ID from './index.testid';
import RealworksHelpBlock from './components/AddTokenContainerWizard/components/RealworksHelpBlock';

type Props = {
  appStatus: AppStatusRealworksFieldsFragment;
  refreshAppStatus: (
    variables?:
      | Partial<
          Exact<{
            accountId: string;
          }>
        >
      | undefined,
  ) => Promise<ApolloQueryResult<GetAppStatusRealworksQuery>>;
} & RouteComponentProps;

const Connections: React.FCC<Props> = ({ appStatus, refreshAppStatus }) => {
  useEffect(() => {
    if (appStatus.tokens.length === 0) {
      void navigate(`/-/apps/realworks/wizard/`, { replace: true });
    }
    // We only want to check this on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tokenTransitions = useTransition(appStatus.tokens, {
    from: {
      opacity: 0,
      transform: 'translate(0, 40px)',
    },
    enter: {
      opacity: 1,
      transform: 'translate(0, 0px)',
    },
    leave: {
      opacity: 0,
      transform: 'translate(0, 40px)',
      height: 0,
    },
    trail: 125,
    keys: tokenContainer => tokenContainer.id,
  });

  return (
    <AppDetailsContainer
      header="Koppelingen overzicht"
      icon="link"
      loading={false}
      dataTestId={TEST_ID.CONTAINER}
    >
      <Body>
        Met een API koppeling voeg je Realworks contacten toe in DatHuis of
        stuur je leads door naar Realworks. Kies de Relaties API om contacten
        uit Realworks in DatHuis toe te voegen. Gebruik de Wonen API om leads
        door te sturen naar Realworks.
      </Body>

      <RealworksHelpBlock tipId="realworks-connections-tip" />

      {tokenTransitions((style, tokenContainer) => (
        <animated.div style={style}>
          <Divider />
          <TokenContainer
            key={tokenContainer.id}
            {...tokenContainer}
            refreshAppStatus={refreshAppStatus}
          />
        </animated.div>
      ))}

      <Divider />
      <Button
        ghost
        appearance="secondary"
        label="Nog een koppeling toevoegen"
        icon="plus"
        size="medium"
        style={{ width: '100%', justifyContent: 'flex-start' }}
        onClick={() => navigate(`/-/apps/realworks/wizard/`)}
        dataTestId={TEST_ID.ADD_TOKEN_CONTAINER}
      />
      <DHRouter>
        <AddTokenContainerWizard
          path="/wizard/*"
          onClose={() => {
            void refreshAppStatus().then(() => {
              void navigate('/-/apps/realworks');
            });
          }}
          refreshAppStatus={refreshAppStatus}
        />
      </DHRouter>
    </AppDetailsContainer>
  );
};

export default Connections;
