import type {
  DeepLink_Config_Mapping_ValueFragment,
  DeepLink_ConfigFragment,
  DeepLinkFragment,
} from '~/graphql/types';
import { assertNever } from '~/util/assertNever';

/**
 * Converts DeepLink_Config_Mapping into DeepLink_Mapping
 * @param mapping
 * @returns
 */
const convertDeepLinkConfigMappingToMapping = (
  mapping: DeepLink_ConfigFragment['mapping'],
): DeepLinkFragment['link']['mapping'] => {
  if (!mapping) return [];

  const result = mapping.reduce(
    (prev, item) => {
      if (!item || !item.value) return prev;

      prev.push({
        __typename: 'DeepLink_Mapping',
        value: getMappingValue(item.value),
        key: item.key,
      });

      return prev;
    },
    [] as DeepLinkFragment['link']['mapping'],
  );

  return result ?? [];
};

const getMappingValue = (
  value: NonNullable<DeepLink_ConfigFragment['mapping'][number]['value']>,
): DeepLink_Config_Mapping_ValueFragment => {
  switch (value.__typename) {
    case 'Flow___Argument_Boolean':
      return {
        __typename: 'Flow___Argument_Boolean',
        value_boolean: value.value_boolean,
      };

    case 'Flow___Argument_Integer':
      return {
        __typename: 'Flow___Argument_Integer',
        value_integer: value.value_integer,
      };

    case 'Flow___Argument_Pointer':
      return {
        __typename: 'Flow___Argument_Pointer',
        pointer: value.pointer,
      };

    case 'Flow___Argument_String':
      return {
        __typename: 'Flow___Argument_String',
        value_string: value.value_string,
      };

    default:
      return assertNever(value);
  }
};

export default convertDeepLinkConfigMappingToMapping;
