import { isNil, pluck } from 'ramda';
import type {
  FlowData___ActionFragment,
  FlowV2_TemplateStringFragment,
  SessionHydrationOfficeFieldsFragment,
} from '~/graphql/types';
import type { ClientFlowAction } from '~/graphql/types.client';
import type { RelativeMaps } from '~/components/page/Automation/v2/types';
import {
  Issue,
  ValidationIssue,
} from '~/components/page/Automation/v2/state/flowIssues';
import { assertNeverWithoutThrowing } from '~/util/assertion';
import getCardHeading from '../../../Builder/utils/getCardHeading';
import getPathForPointer from '../../components/Selector/utils/getPathForPointer';
import validationMap, { messages, ValidationObj } from './validationMap';
import { ExpandedUsers } from '~/hooks/useUsers';
import collectIssuesForEntities from './utils/collectIssuesForEntities';
import collectIssuesForPointerAttachments from './utils/collectIssuesForPointerAttachments';
import collectIssuesForImages from './utils/collectIssuesForImages';
import getIssuesForConditionExpression from '../../../Builder/getIssuesForConditionExpression';
import { reporter } from '~/hooks/useErrorReporter';
import collectIssuesForDeepLinks from './utils/collectIssuesForDeepLinks';
import type { DeepLinksMap } from '../../../../state/deepLinks';

export type IssueOptions = {
  offices?: Array<SessionHydrationOfficeFieldsFragment>;
  users?: ExpandedUsers;
  zapier?: {
    triggers: Array<string>;
  };
};

type Params = {
  action: ClientFlowAction;
  relativeMaps: RelativeMaps;
  availableActions?: Array<FlowData___ActionFragment>;
  opts?: IssueOptions;
  deepLinksMap: DeepLinksMap;
};

/**
 * Returns an array of all issues for given action
 * @param {ClientFlowAction} action - Any kind of client flow action
 * keywords: issues,flow,actions,validation
 */
const getIssuesForAction = ({
  action,
  relativeMaps,
  availableActions,
  opts = {},
  deepLinksMap,
}: Params): Array<Issue> => {
  const availableActionTypes = pluck('type', availableActions ?? []);
  const isActionAvailable = availableActionTypes.includes(action.actionType);
  let issues: Array<Issue> = [];

  // availableActions are not always passed so we only want to validate when its available
  if (availableActions && !isActionAvailable) {
    issues.push({
      type: 'UnavailableActionIssue',
      level: 'error',
      actionId: action.id,
      actionType: action.actionType,
      message: `De actie "${getCardHeading(
        action.actionType,
      )}" is niet (meer) beschikbaar`,
      pathToApp: action.appSlug || null,
    });
  }

  switch (action.__typename) {
    case 'FlowV2_Action_Start':
      issues = [
        ...issues,
        ...getIssuesForConditionExpression(
          {
            condition: action.condition,
            actionId: action.id,
            actionType: action.actionType,
          },
          { maps: relativeMaps, hasTriggers: true, maxTriggers: 1 },
          deepLinksMap,
        ),
      ];

      break;
    case 'FlowV2_Action_IfElse':
      issues = [
        ...issues,
        ...getIssuesForConditionExpression(
          {
            condition: action.condition,
            actionId: action.id,
            actionType: action.actionType,
          },
          { maps: relativeMaps, hasTriggers: false },
          deepLinksMap,
        ),
      ];

      break;
    case 'FlowV2_Action_Wait': {
      issues = [
        ...issues,
        ...getIssuesForConditionExpression(
          {
            condition: action.condition,
            actionId: action.id,
            actionType: action.actionType,
          },
          { maps: relativeMaps, hasTriggers: true },
          deepLinksMap,
        ),
      ];

      break;
    }
    case 'FlowV2_Action_SendEmail_Plain': {
      issues = [
        ...issues,
        ...collectIssuesForTemplateString(action, action.subject, relativeMaps),
        ...collectIssuesForTemplateString(action, action.body, relativeMaps),
        ...collectIssues(action, validationMap[action.__typename]),
        ...collectIssuesForPointerAttachments(
          action,
          action.attachments,
          relativeMaps,
        ),
        ...collectIssuesForImages(action),
        ...collectIssuesForDeepLinks(action, deepLinksMap),
      ];

      break;
    }

    case 'FlowV2_Action_SendNotification': {
      issues = [
        ...issues,
        ...collectIssuesForTemplateString(action, action.subject, relativeMaps),
        ...collectIssuesForTemplateString(action, action.body, relativeMaps),
        ...collectIssues(action, validationMap[action.__typename]),
        ...collectIssuesForPointerAttachments(
          action,
          action.attachments,
          relativeMaps,
        ),
      ];

      break;
    }

    case 'FlowV2_Action_Task_Create': {
      issues = [
        ...issues,
        ...collectIssuesForTemplateString(action, action.title, relativeMaps),
        ...collectIssuesForTemplateString(
          action,
          action.description,
          relativeMaps,
        ),
        ...collectIssues(action, validationMap[action.__typename]),
        ...collectIssuesForEntities({
          action,
          opts,
          officeId: action.assignedToOffice,
          userId: action.user,
        }),
      ];
      break;
    }

    case 'FlowV2_Action_Realworks_SendContact': {
      issues = [
        ...issues,
        ...collectIssuesForTemplateString(
          action,
          action.description,
          relativeMaps,
        ),
        ...collectIssues(action, validationMap[action.__typename]),
      ];
      break;
    }

    case 'FlowV2_Action_Contact_AddTag':
    case 'FlowV2_Action_Contact_DeleteTag': {
      issues = [
        ...issues,
        ...collectIssues(action, validationMap[action.__typename]),
      ];

      break;
    }
    case 'FlowV2_Action_Contact_Details': {
      issues = [
        ...issues,
        ...collectIssues(action, validationMap[action.__typename]),
        ...(action.field.name
          ? collectIssuesForTemplateString(
              action,
              action.field.name,
              relativeMaps,
            )
          : []),
        ...(action.field.phone
          ? collectIssuesForTemplateString(
              action,
              action.field.phone,
              relativeMaps,
            )
          : []),
      ];

      break;
    }

    case 'FlowV2_Action_Zapier_Trigger': {
      issues = [
        ...issues,
        ...collectIssues(action, validationMap[action.__typename]),
        ...checkZapierTriggerOutOfBound(action, opts),
      ];

      break;
    }

    case 'FlowV2_Action_Contact_Assign': {
      issues = [
        ...issues,
        ...collectIssues(action, validationMap[action.__typename]),
        ...collectIssuesForEntities({
          action,
          opts,
          officeId: action.office,
          userId: action.user,
        }),
      ];

      break;
    }

    default:
      assertNeverWithoutThrowing(action, '');
      return [];
  }

  return issues;
};

const checkZapierTriggerOutOfBound = (
  action: ClientFlowAction,
  opts?: IssueOptions,
): Array<Issue> => {
  if (action.__typename !== 'FlowV2_Action_Zapier_Trigger') {
    const err = new Error(
      'This function can only collect issues for Zapier actions',
    );
    reporter.captureException(err);
    throw err;
  }

  if (!opts?.zapier) return [];
  const foundTrigger = opts.zapier.triggers.find(
    id => id === action.zapierTriggerId,
  );

  if (isNil(foundTrigger)) {
    return [
      {
        message: messages.zapier.missingTrigger,
        level: 'error',
        type: 'ZapierTriggerOutOfBounds',
        actionId: action.id,
        actionType: action.actionType,
      },
    ];
  }

  return [];
};

const collectIssuesForTemplateString = (
  action: ClientFlowAction,
  templateString: FlowV2_TemplateStringFragment,
  relativeMaps?: RelativeMaps,
) => {
  const issues: Array<Issue> = [];
  if (!relativeMaps) return [];

  for (const mapping of templateString.mappings) {
    if (
      mapping.mapping?.__typename === 'Flow___Argument_Pointer' &&
      relativeMaps
    ) {
      const pathToPointer = getPathForPointer(
        mapping.mapping.pointer,
        relativeMaps,
      );

      if (pathToPointer.error) {
        issues.push({
          message:
            'Er mist een variabele, waarschijnlijk heb je een stap aangepast of verwijderd',
          level: 'error',
          type: 'BadVariableIssue',
          actionId: action.id,
          actionType: action.actionType,
        });
      }
    }
  }

  return issues;
};

const collectIssues = (
  action: ClientFlowAction,
  validation: ValidationObj,
): Array<ValidationIssue> => {
  if (!validation) return [];
  const issues: Array<ValidationIssue> = [];

  if (validation.interField) {
    const msg = validation.interField(action);
    if (msg !== null) {
      issues.push({
        key: 'interField',
        message: msg,
        level: 'error',
        type: 'ValidationIssue',
        actionId: action.id,
        actionType: action.actionType,
      });
    }
  }

  Object.keys(validation)
    .filter(key => key !== 'interField')
    .forEach((key: string) => {
      const fn = validation[key];
      const msg = !isNil(fn) ? fn(action[key]) : null;

      if (msg !== null) {
        issues.push({
          key,
          message: msg,
          level: 'error',
          type: 'ValidationIssue',
          actionId: action.id,
          actionType: action.actionType,
        });
      }
    });

  return issues;
};

export default getIssuesForAction;
