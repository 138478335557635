import React, { useEffect, useState } from 'react';
import { Task } from '~/components/page/Tasks/types';
import type { GroupedActivities } from '~/state/activities';
import { type EventTimelineContact } from '../..';
import CardGroup from '../CardGroup';
import Loading from '~/components/atom/Loading';
import ErrorScreen from '~/components/page/ErrorScreen';

export type Props = {
  dataTestId?: string;
  className?: string;
  groupedActivities: GroupedActivities;
  targetActivityId: string | null;
  loading?: boolean;
  hasError?: boolean;
  contact: EventTimelineContact;
  fetchUntilActivityFound: (activityId: string) => Promise<boolean>;
  onOpenTaskModal?: (task: Task | null) => void;
};

const EventTimelineContent: React.FCC<Props> = ({
  hasError,
  loading,
  ...rest
}) => {
  if (loading) return <Loading />;
  if (hasError) return <ErrorScreen />;

  return <Child {...rest} />;
};

const Child: React.FC<Omit<Props, 'loading' | 'hasError'>> = ({
  groupedActivities,
  contact,
  targetActivityId,
  onOpenTaskModal,
  fetchUntilActivityFound,
}) => {
  const [hasScrolled, setHasScrolled] = useState(false);

  useEffect(() => {
    if (!targetActivityId || hasScrolled) return;

    const findAndScrollToActivity = async () => {
      const found = await fetchUntilActivityFound(targetActivityId);

      if (found) {
        const scrollTimeoutId = setTimeout(() => {
          const targetActivity = document.getElementById(targetActivityId);
          if (targetActivity) {
            targetActivity.scrollIntoView({
              behavior: 'smooth',
              block: 'center',
            });
            setHasScrolled(true);
          }
        }, 500);

        return () => clearTimeout(scrollTimeoutId);
      }

      return () => {};
    };

    void findAndScrollToActivity();
  }, [targetActivityId, hasScrolled, fetchUntilActivityFound]);

  return (
    <>
      {Object.keys(groupedActivities).map(date => (
        <CardGroup
          onOpenTaskModal={onOpenTaskModal}
          date={date}
          items={groupedActivities[date] ?? []}
          contact={contact}
          key={date}
        />
      ))}
    </>
  );
};

export default EventTimelineContent;
