import React, { useEffect, useState } from 'react';
import {
  Mailboxes,
  AliasMailbox,
  AccountMailbox,
  OfficeMailbox,
  UserMailbox,
} from '~/graphql/types.client.flow';
import {
  EmailAliasStatus,
  EmailSyncStatus,
  useDesyncEmailMutation,
} from '~/graphql/types';

import {
  MAILBOX_TYPE,
  isErrorEmailSyncStatus,
  isSuccessEmailSyncStatus,
} from '~/util/constants';
import SynchronisedMailbox from './SynchronisedMailbox';
import cleanedFilename from '~/util/cleanedFilename';
import { convertServerDateStringToDate } from '~/util/date';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import { Body, Heading3, Label } from '~/components/atom/Typography';
import { reporter } from '~/hooks/useErrorReporter';
import ChooseEmailSyncType from '~/components/molecule/ChooseEmailSyncType';
import { SynchroniseEntityType } from '~/components/page/External/Nylas/types';
import buildStateString from '~/components/page/External/Nylas/utils/buildStateString';
import CallToActionBlock from '~/components/organism/CallToActionBlock';
import Modal from '~/components/organism/ModalsV2/Modal';
import Overlay from '~/components/organism/ModalsV2/Overlay';
import JustificationContainer from '~/components/atom/JustificationContainer';
import AppErrorScreen from '~/components/template/AppErrorScreen';
import useRunOnce from '../hooks/useRunOnce';
import type { IconType } from '~/components/atom/Icon';

const text = {
  inputLabel: 'E-mail',
  deleteError:
    'Er is iets fout gegaan bij het verwijderen van het gesynchroniseerde e-mail account. Probeer het later nog eens',
  info: 'Bespaar tijd door automatisch e-mail vanuit je eigen inbox te versturen. DatHuis koppelt e-mails automatisch aan de betreffende contacten.',
  resyncNeededSyncIsBroken:
    'De koppeling met jouw mailbox is verbroken. Klik hier om opnieuw te koppelen.',
  resyncNeededButSyncIsRunning:
    'De e-mailkoppeling verloopt binnenkort. Klik hier om opnieuw te synchroniseren.',
  resyncButtonLabel: 'Opnieuw koppelen',
  resyncModalHeader: 'Synchroniseren',
};

type Props = {
  /** Where this component is from */
  entityType: SynchroniseEntityType;

  /** The id of the entity (account, office, user) */
  entityId: string;

  /** Any e-mail address that should be used as placeholder */
  email?: string;

  /** If the user may edit the mailboxes */
  mayEdit: boolean;

  /** Explain who can edit it */
  mayEditExplanation?: string;

  /** Mailboxes to be displayed */
  mailboxes: Mailboxes;

  /** Refetch the current query on the page (getOffice, getUser, getAccount etc.) and session hydration */
  refetch: () => Promise<any>;

  /** Start polling for the query mentioned above */
  startPolling: (pollInterval: number) => void;

  /** Stop polling for the same query */
  stopPolling: () => void;
};

const SynchronisedMailboxes: React.FCC<Props> = ({
  entityType,
  entityId,
  mailboxes,
  mayEdit,
  mayEditExplanation,
  startPolling,
  stopPolling,
  refetch,
}) => {
  const account = useCurrentAccount();
  const [showModal, setShowModal] = useState(false);
  const [runOnceOnSuccessfulAliasVerificationEnd] = useRunOnce(stopPolling);

  const [desyncEmail, { loading: desyncLoading, error }] =
    useDesyncEmailMutation();

  const primaryMailboxes = mailboxes.filter(
    (mailbox): mailbox is AccountMailbox | OfficeMailbox | UserMailbox =>
      mailbox.__typename === MAILBOX_TYPE.AccountMailbox ||
      mailbox.__typename === MAILBOX_TYPE.OfficeMailbox ||
      mailbox.__typename === MAILBOX_TYPE.UserMailbox,
  );
  if (primaryMailboxes.length > 1) {
    reporter.captureException(
      new Error(
        `${cleanedFilename(
          __filename,
        )} | Should not occur | Second primary mailbox found, we only support a single e-mail`,
      ),
    );
  }
  const primaryMailbox = primaryMailboxes[0];

  const aliasMailboxes = mailboxes.filter(
    (mailbox): mailbox is AliasMailbox =>
      mailbox.__typename === MAILBOX_TYPE.AccountMailboxAlias ||
      mailbox.__typename === MAILBOX_TYPE.OfficeMailboxAlias ||
      mailbox.__typename === MAILBOX_TYPE.UserMailboxAlias,
  );
  if (aliasMailboxes.length > 1) {
    reporter.captureException(
      new Error(
        `${cleanedFilename(
          __filename,
        )} | Should not occur | Second alias mailbox found, we only support a single e-mail`,
      ),
    );
  }
  const aliasMailbox = aliasMailboxes[0];

  useEffect(() => {
    if (
      primaryMailboxes.length === 1 &&
      primaryMailboxes.find(
        m =>
          isSuccessEmailSyncStatus(m.email.syncState) &&
          m.email.syncState !== EmailSyncStatus.MigrationResyncNeeded,
      )
    ) {
      stopPolling();
      if (showModal) setShowModal(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [primaryMailboxes]);

  if (error) {
    return <AppErrorScreen inline message={text.deleteError} />;
  }

  const hasMultipleMailboxes =
    primaryMailboxes.length > 1 || aliasMailboxes.length > 1;

  if (mayEdit && !primaryMailbox)
    return (
      <>
        <Body>{text.info}</Body>
        {mayEditExplanation && <Label>{mayEditExplanation}</Label>}
        <ChooseEmailSyncType
          entityType={entityType}
          accountId={account.id}
          entityId={entityId}
          refetch={refetch}
          stateStr={buildStateString({
            entityType,
            entityId,
            accountId: account.id,
          })}
          startPolling={startPolling}
          stopPolling={stopPolling}
          hasMultipleMailboxes={hasMultipleMailboxes}
        />
      </>
    );

  if (!primaryMailbox)
    return (
      <>
        <Body>{text.info}</Body>
        {mayEditExplanation && <Label>{mayEditExplanation}</Label>}
      </>
    );

  const { email, syncState } = primaryMailbox.email;

  let aliasEmail: typeof aliasMailbox.email.emailAlias | null = null;
  let aliasSyncState: typeof aliasMailbox.email.status | null = null;
  let aliasValidatedDate: Date | null = null;
  if (aliasMailbox != null) {
    aliasEmail = aliasMailbox.email.emailAlias;
    aliasSyncState = aliasMailbox.email.status;
    aliasValidatedDate = convertServerDateStringToDate(
      aliasMailbox.email.validatedDate,
    );

    if (aliasSyncState === EmailAliasStatus.Verified) {
      runOnceOnSuccessfulAliasVerificationEnd();
    }
  }

  const renderCallToActionBlock = (description: string, iconName: IconType) => (
    <CallToActionBlock
      padding={['base']}
      margin={[null, null, 'l', null]}
      description={description}
      button={{
        label: text.resyncButtonLabel,
        onClick: () => setShowModal(true),
        icon: 'reload',
        appearance: 'primary',
        disabled: !mayEdit,
      }}
      icon={{
        background: { group: 'warning' },
        name: iconName,
      }}
    />
  );

  return (
    <>
      {showModal && (
        <Overlay onClose={() => setShowModal(false)}>
          <Modal onClose={() => setShowModal(false)} maxWidth="600px">
            <JustificationContainer direction="column" padding={['l']}>
              <Heading3>{text.resyncModalHeader}</Heading3>
              <Body>{text.info}</Body>
              {mayEditExplanation && <Label>{mayEditExplanation}</Label>}
              <ChooseEmailSyncType
                entityType={entityType}
                accountId={account.id}
                entityId={entityId}
                refetch={refetch}
                stateStr={buildStateString({
                  entityType,
                  entityId,
                  accountId: account.id,
                })}
                startPolling={startPolling}
                stopPolling={stopPolling}
                hasMultipleMailboxes={hasMultipleMailboxes}
              />
            </JustificationContainer>
          </Modal>
        </Overlay>
      )}

      <Body margin={['m', null]}>{text.info}</Body>
      {mayEditExplanation && <Label>{mayEditExplanation}</Label>}

      {isErrorEmailSyncStatus(syncState) &&
        renderCallToActionBlock(text.resyncNeededSyncIsBroken, 'alert-circle')}

      {syncState === EmailSyncStatus.MigrationResyncNeeded &&
        renderCallToActionBlock(text.resyncNeededButSyncIsRunning, 'triangle')}

      <SynchronisedMailbox
        initialEmailAlias={aliasEmail}
        emailAliasState={aliasSyncState}
        emailAliasValidatedDate={aliasValidatedDate}
        entityType={entityType}
        entityId={entityId}
        email={email}
        syncState={syncState}
        mayEdit={mayEdit}
        onDelete={() =>
          desyncEmail({
            variables: {
              accountId: account.id,
              email,
            },
          }).then(() => refetch())
        }
        loading={desyncLoading}
        refetch={refetch}
        startPolling={startPolling}
        stopPolling={stopPolling}
      />
    </>
  );
};

export default SynchronisedMailboxes;
