import type { ApolloError, ApolloQueryResult } from '@apollo/client';
import { useEffect, useState } from 'react';
import {
  type GetMyAccountsQuery,
  type Exact,
  useGetMyAccountsQuery,
} from '~/graphql/types';
import hasValue from '~/util/hasValue';
import {
  SELECTED_ACCOUNT_STORAGE_KEY,
  ACCOUNT_ID_CURRENTLY_IMPERSONATED,
  removeLocalStorageItem,
} from '~/util/localStorageKeys';

type ReturnType = {
  data?: GetMyAccountsQuery;
  loading?: boolean;
  error?: ApolloError;
  refetch: (
    variables?:
      | Partial<
          Exact<{
            [key: string]: never;
          }>
        >
      | undefined,
  ) => Promise<ApolloQueryResult<GetMyAccountsQuery>>;
  accountId: string | null;
} | void;

const IMPERSONATION_VALIDATION_DELAY = 1000;

/**
 * Custom hook to retrieve and manage the current account from the list of available accounts.
 *
 * This hook interacts with the `useGetMyAccountsQuery` to fetch account data,
 * manages local storage to determine the currently selected or impersonated account,
 * and handles edge cases such as expired impersonation sessions.
 *
 * @returns {ReturnType} An object containing:
 *   - `data`: Query result data containing the list of user accounts.
 *   - `loading`: A boolean indicating if the query is in progress.
 *   - `error`: An ApolloError instance if the query fails.
 *   - `refetch`: A function to manually refetch the query data.
 *   - `accountId`: The ID of the currently active account, which can be:
 *     - The currently impersonated account (if valid).
 *     - The user-selected account stored in local storage.
 *     - The first account in the list if no selection has been made.
 *
 * Behavior:
 * 1. **No Accounts Available**: If no account data is fetched or the list is empty,
 *    the hook returns `accountId: null`.
 *
 * 2. **Impersonation Management**:
 *    - If an impersonated account ID exists in local storage but is no longer valid
 *      (i.e., not found in the fetched account list), it is removed from local storage,
 *      and the page reloads to reset the impersonation state.
 *    - If a valid impersonated account is found, its ID is returned as `accountId`.
 *
 * 3. **Selected Account**:
 *    - If the user has a selected account ID stored in local storage, and it exists
 *      in the fetched account list, that account ID is returned as `accountId`.
 *
 * 4. **Default Account**:
 *    - If no impersonation or selection exists, the first account in the fetched list
 *      is set as the selected account in local storage and returned as `accountId`.
 */
const useGetCurrentAccount = (): ReturnType => {
  const { data, loading, error, refetch } = useGetMyAccountsQuery();
  const [checkTimeout, setCheckTimeout] = useState<NodeJS.Timeout | null>(null);

  useEffect(
    () => () => {
      if (checkTimeout) {
        clearTimeout(checkTimeout);
      }
    },
    [checkTimeout],
  );

  const queryProps = { data, loading, error, refetch };
  if (loading) return { data, loading, error, refetch, accountId: null };

  if (!data || data.getMyAccounts.length === 0)
    return { ...queryProps, accountId: null };

  const accounts = data.getMyAccounts;
  const selectedAccountId = global.localStorage.getItem(
    SELECTED_ACCOUNT_STORAGE_KEY,
  );

  const currentlyImpersonatedAccount = global.localStorage.getItem(
    ACCOUNT_ID_CURRENTLY_IMPERSONATED,
  );
  const validImpersonationAccount = accounts.find(
    acc => acc.id === currentlyImpersonatedAccount,
  );

  /**
   * Handle two critical impersonation scenarios:
   *
   * 1. Race Condition During Impersonation Start:
   *    When impersonation starts, there's a brief moment where the localStorage is set
   *    but the GraphQL query hasn't refetched yet. Without this delay, it would
   *    incorrectly detect this as an invalid state and remove the impersonation.
   *
   * 2. Expired Impersonation Session:
   *    If a user keeps the window open for a long time and their impersonation session
   *    expires, the account will no longer be in the query results. In this case,
   *    we want to detect this, clean up the localStorage, and reload the page.
   *
   * Solution:
   * - When we detect a potential invalid state, wait 1 second and refetch
   * - If the account is still invalid after refetch, then clean up and reload
   * - During this check, maintain the current impersonation state to prevent flicker
   */
  if (hasValue(currentlyImpersonatedAccount) && !validImpersonationAccount) {
    // 1. First detection of invalid state
    if (!checkTimeout) {
      // 2. Double check after 1 second with fresh data
      const timeout = setTimeout(async () => {
        // Refetch to get fresh data
        const result = await refetch();
        const freshAccounts = result.data.getMyAccounts;

        const stillInvalid = !freshAccounts.find(
          acc => acc.id === currentlyImpersonatedAccount,
        );

        if (stillInvalid) {
          // 3. Confirmed expired - clean up and reload
          removeLocalStorageItem(ACCOUNT_ID_CURRENTLY_IMPERSONATED);
          window.location.reload();
        }
      }, IMPERSONATION_VALIDATION_DELAY);

      setCheckTimeout(timeout);
    }
    // 4. Meanwhile, keep showing the current state
    return { ...queryProps, accountId: currentlyImpersonatedAccount };
  }

  if (validImpersonationAccount)
    return { ...queryProps, accountId: currentlyImpersonatedAccount };

  if (selectedAccountId && accounts.find(acc => acc.id === selectedAccountId))
    return { ...queryProps, accountId: selectedAccountId };

  const headAccountId = accounts[0].id;
  global.localStorage.setItem(SELECTED_ACCOUNT_STORAGE_KEY, headAccountId);
  return { ...queryProps, accountId: headAccountId };
};

export default useGetCurrentAccount;
