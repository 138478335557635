import { Props } from '~/components/organism/SendEmailBlock/components/EmailInputFields';
import { isNil } from 'ramda';
import convertHtmlToSlateFragment from '~/components/organism/PluginsEditor/utils/signatures/convertHtmlToSlateFragment';
import ELEMENTS from '~/components/organism/PluginsEditor/components/elements/elementsEnum';
import type { SendableMailbox } from '~/hooks/useAvailableEmailsToSendFrom';
import type { EmailReducerState } from '../../reducers/sendEmail';
import getDropdownOptions from '../getDropdownOptions';
import getNamedEmailListFromProp from '../getNamedEmailListFromProp';

type GetInitialValuesReturnType = EmailReducerState & {
  shouldValidate: boolean;
};

/**
 * Return initial values for SendEmailBlock
 * @param {Props} props - EmailInputFields props
 * @param {Props} availableEmailsToSendFrom - EmailInputFields props
 * keywords: sendEmail, initialValues
 */
const getInitialFormValues = (
  props: Props,
  availableEmailsToSendFrom: Array<SendableMailbox>,
  accountId: string,
): GetInitialValuesReturnType => {
  const { sender, subject, cc, bcc } = props;

  const {
    options: senderOptions,
    initialSelectedIdx: initiallySelectedSenderIdx,
  } = getDropdownOptions({
    availableEmailsToSendFrom,
    initiallySelectedEmail: sender?.namedEmail,
    accountId,
  });

  const initialSender = !isNil(initiallySelectedSenderIdx)
    ? senderOptions[initiallySelectedSenderIdx].payload
    : null;

  const convertedHtml = convertHtmlToSlateFragment({
    html: props.body?.text ?? '',
    attachments: [],
    customElements: [ELEMENTS.SIGNATURE, ELEMENTS.DH_IMAGE, ELEMENTS.IMAGE],
  });

  return {
    subject: subject?.text ?? '',
    body: convertedHtml,
    shouldValidate: false,
    senderOptions,
    selectedSenderIdx: initiallySelectedSenderIdx,
    senderValue: initialSender,
    selectedCCs: cc ? getNamedEmailListFromProp(cc) : [],
    selectedBCCs: bcc ? getNamedEmailListFromProp(bcc) : [],
  };
};

export default getInitialFormValues;
