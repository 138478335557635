import React from 'react';
import {
  RenderElementProps,
  useFocused,
  useSelected,
  useSlate,
} from 'slate-react';
import ELEMENTS from '~/components/organism/PluginsEditor/components/elements/elementsEnum';
import { VariableElement } from '~/components/organism/PluginsEditor/types';
import serialize from './serialize';
import TEST_ID from './index.testid';
import { BAD_VARIABLE_NAME } from '~/components/organism/PluginsEditor/utils/flows/convertVariablesInHtml';
import ActionLabelAsText from '~/components/page/Automation/v2/components/Builder/components/ActionLabelAsText';
import EditableBlockWithIcon from '../components/EditableBlockWithIcon';
import useDragProps from '../../../hooks/useDragProps';

export type Props = RenderElementProps & {
  element: VariableElement;
};

const text = {
  badVariable: 'Foutieve variabele',
};

const Variable: React.FCC<Props> = ({ attributes, element, children }) => {
  const editor = useSlate();
  const selected = useSelected();
  const focused = useFocused();
  const active = selected && focused;

  const isBadVariable = element.variableName === BAD_VARIABLE_NAME;
  const dragProps = useDragProps(editor);

  return (
    <span {...attributes} data-testid={TEST_ID.CONTAINER} {...dragProps}>
      {/* A hacky way to position the Variable hovering toolbar correctly at the end
      and beginning of new lines. We add children twice as spacers */}
      {children}
      <EditableBlockWithIcon
        iconName="user"
        active={active}
        hasError={isBadVariable}
      >
        {isBadVariable ? (
          <>{text.badVariable}</>
        ) : (
          <ActionLabelAsText str={element.variableName || null} />
        )}
      </EditableBlockWithIcon>

      {children}
    </span>
  );
};

export default {
  /** We pass a random element name for variables so that we can render this component when deserializing */
  nodeName: 'DHVARIABLE',
  renderComponent: props => <Variable {...props} />,
  serialize,
  deserialize: el => ({
    type: ELEMENTS.VARIABLE,
    mappingId: el.getAttribute('dhmappingid'),
    variableName: el.getAttribute('dhvariablename'),
    variableType: el.getAttribute('dhvariabletype'),
    variableInfo: JSON.parse(
      el.getAttribute('dhvariableinfo').replace(/'/g, '"'),
    ),
  }),
};
