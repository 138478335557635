enum ELEMENTS {
  BLOCKQUOTE = 'block-quote',
  DH_IMAGE = 'dh-image',
  DIV = 'div',
  PARAGRAPH = 'paragraph',
  IMAGE = 'image',
  LINK = 'link',
  SPAN = 'span',
  OL = 'numbered-list',
  UL = 'bulleted-list',
  LI = 'list-item',
  VARIABLE = 'dh-variable',
  DEEP_LINK = 'dh-deeplink',
  SIGNATURE = 'dh-signature',
  H1 = 'heading-one',
  H2 = 'heading-two',
  H3 = 'heading-three',
  H4 = 'heading-four',
  H5 = 'heading-five',
  H6 = 'heading-six',

  /**
   * Any element that is not included in allElements object will be deserialized
   * as generic-html-element which means it will have its initial attributes and
   * it will be saved as it came. It won't be changed in any stage.
   */
  GENERIC_HTML_ELEMENT = 'generic-html-element',

  /**
   * A block element that is used to display HTML as it is. Its children are not kept in Slate.
   * It cannot be focused or edited. Used to pass through some html directly to backend.
   */
  IMMUTABLE_HTML_ELEMENT = 'immutable-html-element',

  /**
   * Represents an error boundary within the editor. When this element is present as the first child
   * in the editor's value array, it triggers the rendering of an error screen instead of the normal editor content.
   * This state indicates a critical issue where the editor cannot proceed with normal operation and requires
   * immediate attention to resolve underlying issues. Use this element to safeguard the application by providing
   * a clear fallback UI and preventing further errors from propagating.
   */
  ERROR_BOUNDARY = 'error-boundary',
}

export default ELEMENTS;
