import { isNil, last } from 'ramda';
import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import styled, { css } from 'styled-components';
import type { OptionOf } from '~/components/molecule/Dropdown';
import Selector from '~/components/organism/Selector';
import { previousNodesById } from '~/components/page/Forms/components/Builder/state/nodesAndEvents';
import type { BlockWithOutput } from '~/components/page/Forms/components/Builder/utils/typeguards/isBlockWithOutput';
import isBlockWithOutput from '~/components/page/Forms/components/Builder/utils/typeguards/isBlockWithOutput';
import isSelectTypeBlock from '~/components/page/Forms/components/Builder/utils/typeguards/isSelectTypeBlock';
import {
  FormBuilder_PrimitiveType,
  type FormBuilder_Event_FieldFragment,
  type FormBuilder_ScreenNodeFragment,
} from '~/graphql/types';

const superTypeMapping: Record<
  BlockWithOutput['__typename'],
  Array<FormBuilder_PrimitiveType>
> = {
  FormData_Input_Email: [
    FormBuilder_PrimitiveType.Email,
    FormBuilder_PrimitiveType.String,
  ],
  FormData_Input_Integer: [FormBuilder_PrimitiveType.Number],
  FormData_Input_Multiline: [FormBuilder_PrimitiveType.String],
  FormData_Input_Text: [FormBuilder_PrimitiveType.String],
  FormData_Input_Address: [FormBuilder_PrimitiveType.Address],

  FormData_Input_Contact_Address: [FormBuilder_PrimitiveType.Address],
  FormData_Input_Contact_Email: [
    FormBuilder_PrimitiveType.Email,
    FormBuilder_PrimitiveType.String,
  ],
  FormData_Input_Contact_Name: [FormBuilder_PrimitiveType.String],
  FormData_Input_Contact_Phone: [FormBuilder_PrimitiveType.String],

  FormData_Select_Dropdown: [FormBuilder_PrimitiveType.String],
  FormData_Select_MultiButton: [FormBuilder_PrimitiveType.String],
  FormData_Select_Radio: [FormBuilder_PrimitiveType.String],
};

const blockFitsField = (
  block: BlockWithOutput,
  field: FormBuilder_Event_FieldFragment,
): boolean => {
  if (field.type.__typename === 'FormBuilder_Event_Field_Type_Option')
    return false;

  return superTypeMapping[block.__typename].includes(field.type.type);
};

type Options = Array<{
  id: string;
  name: string;
  blocks: Array<OptionOf<[string, string]>>;
}>;

const getOptions = (
  previousNodes: Array<FormBuilder_ScreenNodeFragment>,
  field: FormBuilder_Event_FieldFragment,
): Options => {
  if (field.type.__typename === 'FormBuilder_Event_Field_Type_Primitive') {
    const options = previousNodes.map(node => ({
      id: node.id,
      name: node.name,
      blocks: node.blocks
        .filter(isBlockWithOutput)
        .filter(block => blockFitsField(block, field))
        .map(
          (block): OptionOf<[string, string]> => ({
            key: block.key,
            label: block.label.nl ?? block.label.en,
            payload: [node.id, `${node.id}-${block.key}`],
          }),
        ),
    }));

    return options;
  }

  if (field.type.__typename === 'FormBuilder_Event_Field_Type_Option') {
    const blockHasSameOptionList = ({ block, field }) =>
      field.type.__typename === 'FormBuilder_Event_Field_Type_Option' &&
      field.type.id === block.optionListId;

    const options = previousNodes.map(node => ({
      id: node.id,
      name: node.name,
      blocks: node.blocks
        .filter(isSelectTypeBlock)
        .filter(block => blockHasSameOptionList({ field, block }))
        .map(
          (block): OptionOf<[string, string]> => ({
            key: block.key,
            label: block.label.nl ?? block.label.en,
            payload: [node.id, `${node.id}-${block.key}`],
          }),
        ),
    }));

    return options.filter(a => a);
  }

  return [];
};

const text = {
  emptyBreadcrumbLabel: 'Selecteer een pagina',
};
type Props = {
  field: FormBuilder_Event_FieldFragment;
  eventId: string;
  onClose: () => void;
  onSelect: (pointer: [string, string]) => void;
};

const PointerSelector: React.FCC<Props> = ({
  eventId,
  field,
  onClose,
  onSelect,
}) => {
  const incomingNodes = useRecoilValue(previousNodesById(eventId));
  const options = getOptions(incomingNodes, field);
  const [selectorPath, setSelectorPath] = useState<Array<string>>([]);

  return (
    <Container>
      <Selector
        emptyBreadcrumbLabel={text.emptyBreadcrumbLabel}
        pointerPosition={{
          location: 'top',
          offset: 100,
        }}
        actions={[
          {
            key: 'close',
            icon: 'close',
            appearance: 'icon',
            onClick: onClose,
          },
        ]}
        crumbs={[
          {
            label: '...',
            key: 'root',
          },
        ]}
        onBreadcrumbClick={(_crumb, index) => {
          setSelectorPath(prev => prev.splice(index));
        }}
      >
        {isNil(last(selectorPath))
          ? options.map(({ id, name }) => (
              <ListItem
                key={id}
                onClick={() => {
                  setSelectorPath(prev => [...prev, id]);
                }}
              >
                {name}
              </ListItem>
            ))
          : options
              .find(({ id }) => id === last(selectorPath))
              ?.blocks.map(({ key, label, payload }) => (
                <ListItem
                  key={key}
                  onClick={() => {
                    onSelect(payload);
                    onClose();
                  }}
                >
                  {label}
                </ListItem>
              ))}
      </Selector>
    </Container>
  );
};

const ListItem = styled.li(
  ({ theme }) => css`
    padding: ${theme.space('xs')};
    cursor: pointer;
    color: ${theme.color('text')};
    border-radius: ${theme.getTokens().border.radius.base};
    transition: all 0.3s ease-out;
    /** We need the extra height when there are pointer values that overflow to the next line */
    line-height: 1.8;

    &:hover {
      background-color: ${theme.color('tertiary', 'light')};
      color: ${theme.color('tertiary', 'text')};
    }
  `,
);

const Container = styled.div<{}>`
  ${() => css`
    position: absolute;
    top: 3.5rem;
    left: 0;
    width: 320px;
  `};
`;

export default PointerSelector;
