import React, { useCallback, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import Icon from '~/components/atom/Icon';
import JustificationContainer from '~/components/atom/JustificationContainer';
import TEST_ID from './index.testid';
import { isNil } from 'ramda';
import useGlobalKeyBinding from '~/hooks/useGlobalKeyBinding';
import Input from '~/components/molecule/Input';
import isValidLink from './utils/isValidLink';
import { animated, useTransition } from 'react-spring';
import String from '~/util/Validation/String';
import Button from '~/components/atom/Button';
import { Heading3 } from '~/components/atom/Typography';
import Catalog from '~/Catalog';

export type Props = {
  onSave: (val: { url: string; text: string }) => void;
  urlValue?: string;
  textValue?: string;
  autoFocus?: boolean;
  headingText: string;
};

const text = {
  validationMessage: 'Moet beginnen met http://, https://, mailto: of tel:',
  submit: 'Voer een url in',
  warningMessage: "'tel:' link moet beginnen met een landcode, bijv. +31",
};

/**
 * This marker allows to add the unsubscribe link in the body of an email sent within the flows.
 * Marker is replaced with the actual link by the backend.
 * This is currently a hidden feature, known only to few users we inform.
 */
const UNSUBSCRIBE_MARKER = '<%UNSUB%>';
const _isValidURL = url => isValidLink(url) || url === UNSUBSCRIBE_MARKER;

const EditUrlBlock: React.FCC<Props> = ({
  onSave,
  headingText,
  urlValue = '',
  textValue = '',
  autoFocus = false,
  children,
  ...rest
}) => {
  const [url, setUrl] = useState<string>('');
  const [linkText, setLinkText] = useState<string>('');
  const [hasValidationError, setHasValidationError] = useState<boolean>(false);

  // Set values here so that they are up to date when selected element changes from link to another link
  useEffect(() => {
    setUrl(urlValue);
    setLinkText(textValue);
  }, [urlValue, textValue]);

  const onSubmit = useCallback(() => {
    if (!_isValidURL(url)) {
      setHasValidationError(true);
      return;
    }

    onSave({ url, text: linkText });
  }, [url, linkText, onSave]);

  useGlobalKeyBinding({ keys: 'enter', callback: onSubmit, enabled: true });

  const showWarning = url && String.isString(url) && url.startsWith('tel:');
  const transitions = useTransition(showWarning, {
    from: { transform: 'translateY(10px)', opacity: 0 },
    enter: { transform: 'translateY(-10px)', opacity: 1 },
    leave: { transform: 'translateY(10px)', opacity: 0 },
  });

  return (
    <Container data-testid={TEST_ID.CONTAINER} {...rest}>
      <Heading3 size="base">
        <JustificationContainer align="center" justification="space-between">
          {headingText}{' '}
        </JustificationContainer>
      </Heading3>

      <InputContainer>
        {transitions((style, show) => (
          <>
            {show && (
              <InfoContainer style={style}>
                <Subheader>Let op: </Subheader>
                {text.warningMessage}
              </InfoContainer>
            )}
          </>
        ))}

        <Input
          label={{
            text: (
              <JustificationContainer
                width="100%"
                justification="space-between"
              >
                URL&nbsp;
                <CustomLink
                  href={url}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={e => {
                    if (!url) e.preventDefault();
                  }}
                  $disabled={url.length === 0}
                >
                  <Icon name="externalLink" />
                </CustomLink>
              </JustificationContainer>
            ),
          }}
          value={url}
          onChange={e => {
            if (hasValidationError) setHasValidationError(false);
            if (!isNil(e?.target?.value)) {
              setUrl(e.target.value);
            }
          }}
          type="url"
          autoFocus={autoFocus}
          name="url"
          externalErrors={
            hasValidationError ? [text.validationMessage] : undefined
          }
        />
      </InputContainer>

      <InputContainer>
        <Input
          label={{ text: 'Tekst' }}
          value={linkText}
          onChange={e => {
            if (!isNil(e?.target?.value)) {
              setLinkText(e.target.value || '');
            }
          }}
          name="text"
        />
      </InputContainer>

      <JustificationContainer align="center" justification="end" gap="xxs">
        {children}

        <Button
          label={Catalog.confirm}
          onClick={onSubmit}
          dataTestId={TEST_ID.SUBMIT_BUTTON}
        />
      </JustificationContainer>
    </Container>
  );
};

const Container = styled.div<{}>`
  max-width: 190px;
`;

const InfoContainer = styled(animated.div)<{}>(
  ({ theme }) => css`
    background-color: ${theme.color('primary', 'translucent')};
    border-radius: ${theme.getTokens().border.radius.base};
    padding: ${theme.space('xs')};
  `,
);

const Subheader = styled.span<{}>(
  ({ theme }) => css`
    color: ${theme.color('primary')};
    font-weight: ${theme.fw('semiBold')};
  `,
);

/**
 * When editing the url we are also updating this link.
 * Using the Link component throws an error if this link is not valid (see: the URL consructor in isExternalURL)
 * Therefore we need to use a custom link.
 */
const CustomLink = styled.a<{ $disabled: boolean }>(
  ({ theme, $disabled }) => css`
    color: ${$disabled ? theme.color('tertiary') : theme.color('primary')};
    font-weight: ${theme.fw('semiBold')};
    text-decoration: none;
  `,
);

const InputContainer = styled.div<{}>`
  /* Fixes FloatingLabel overflow */
  label > div:first-child {
    white-space: normal;
    text-align: left;
  }

  ${({ theme }) => css`
    margin: ${theme.space('base')} 0;
  `}
`;

export default EditUrlBlock;
