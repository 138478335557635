import type { OptionOf } from '~/components/molecule/Dropdown';
import { UserStatus } from '~/graphql/types';
import useUsers, { type ExpandedUser } from '../useUsers';

const allUsersOption = {
  label: 'Alle gebruikers',
  key: 'no-selection',
  payload: null,
};

export type UserOptions = Array<OptionOf<ExpandedUser | null>>;

type Filter = {
  officeId?: string | null;
  withAllUsersOption?: boolean;
};

const useUserOptions = (filter?: Filter): UserOptions => {
  const { officeId = null, withAllUsersOption = false } = filter ?? {};

  const usersInOffice = useUsers({
    officeIds: [officeId],
    statuses: [UserStatus.Active],
  });

  const allActiveUsers = useUsers({
    statuses: [UserStatus.Active],
  });

  const options =
    officeId && usersInOffice.length > 0
      ? mapUsersToOptions(usersInOffice)
      : mapUsersToOptions(allActiveUsers);

  return withAllUsersOption ? addAllUsersOption(options) : options;
};

const addAllUsersOption = (options: UserOptions): UserOptions => [
  allUsersOption,
  ...options,
];

const mapUsersToOptions = (users: Array<ExpandedUser>): UserOptions =>
  users
    .map(user => ({
      key: user.id,
      label: user.name,
      payload: user,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

export default useUserOptions;
