import UUID_REGEX from './Uuid';
import String from './String';
export const ID_PREFIX = {
  DEEP_LINK: 'dl--',
};

const DEEP_LINK_ID_REGEX = new RegExp(`^${ID_PREFIX.DEEP_LINK}${UUID_REGEX}$`);
const isValid = (id: any): id is string =>
  String.isNonEmptyString(id) && DEEP_LINK_ID_REGEX.test(id);
export default {
  isValid,
};
