import { ReactEditor } from 'slate-react';
import type { DHEditor } from '../../types';

/**
 * Makes an element draggable in the editor.
 * Sets hasActiveDragging flag on the editor which helps remove the hovering toolbar etc.
 * when the element is being dragged
 *
 * @param editor DHEditor
 * @returns Props to pass to the draggable element
 */
const useDragProps = (
  editor: DHEditor,
): {
  draggable: boolean;
  onDragStart: () => void;
  onDragLeave: () => void;
  onDragEnd: () => void;
} => {
  const onDragStart = () => {
    ReactEditor.blur(editor);
    ReactEditor.deselect(editor);
    editor.hasActiveDragging = true;
  };
  const onDragRemove = () => (editor.hasActiveDragging = false);

  return {
    draggable: true,
    onDragStart,
    onDragLeave: onDragRemove,
    onDragEnd: onDragRemove,
  };
};

export default useDragProps;
