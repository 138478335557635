import { atom } from 'recoil';

/**
 * Allows to focus on the next (undeleted) child of the last deleted action
 */
const lastDeletedActionNextChildId = atom<string | null>({
  key: 'lastDeletedActionNextChildId',
  default: null,
});

export default lastDeletedActionNextChildId;
