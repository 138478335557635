import { atom, selectorFamily } from 'recoil';
import { HandledFlowAction } from '~/graphql/types.client';
import getIssuesForAction from '../components/UpdateAction/utils/getIssuesForAction';
import getUpToDateRelativeMaps from '../components/Builder/utils/getUpToDateRelativeMaps';
import { deepLinks } from './deepLinks';
import flowActions from '.';
import type {
  FlowData___ActionFragment,
  SessionHydrationOfficeFieldsFragment,
} from '~/graphql/types';
import type { ExpandedUsers } from '~/hooks/useUsers';
import type { RelativeMaps } from '../types';

type BaseIssue = {
  level: 'error' | 'warning' | null;
  actionId: string | null;
  actionType: HandledFlowAction | null;
  message: string;
};

export type ConditionIssue = BaseIssue & {
  type: 'ConditionIssue';
  id: string;
  level: 'warning' | 'error';
  conditionSubExpressionIdx: number;
  conditionGroupIdx: number;
  conditionIdx?: number;
  argumentIdx?: number;
};

export type ValidationIssue = BaseIssue & {
  type: 'ValidationIssue';
  key: string;
  level: 'warning' | 'error';
};

export type BadVariableIssue = BaseIssue & {
  type: 'BadVariableIssue';
  level: 'error';
};

export type BrokenFileIssue = BaseIssue & {
  type: 'BrokenFileIssue';
  level: 'error';
};

export type MissingInlineImageIssue = BaseIssue & {
  type: 'MissingInlineImageIssue';
  level: 'error';
};

export type UnavailableActionIssue = BaseIssue & {
  type: 'UnavailableActionIssue';
  level: 'error';
  pathToApp: string | null;
};

export type ZapierTriggerOutOfBoundsIssue = BaseIssue & {
  type: 'ZapierTriggerOutOfBounds';
  level: 'error';
};

export type Issue =
  | ConditionIssue
  | ValidationIssue
  | UnavailableActionIssue
  | BadVariableIssue
  | BrokenFileIssue
  | MissingInlineImageIssue
  | ZapierTriggerOutOfBoundsIssue;

export type IssueMap = Record<string, Array<Issue>>;

const flowIssues = atom<IssueMap>({
  key: 'flowIssues',
  default: {},
});

type IssueOptions = {
  offices?: Array<SessionHydrationOfficeFieldsFragment>;
  users?: ExpandedUsers;
  zapier?: {
    triggers: Array<string>;
  };
};

type Params = {
  actionId: string;
  maps: RelativeMaps;
  availableActions?: Array<FlowData___ActionFragment>;
  opts?: IssueOptions;
};

/**
 * Used to set the flowIssues state and keep it up to date.
 */
export const generatedIssuesForAction = selectorFamily({
  key: 'generatedIssuesForAction',
  get:
    ({ actionId, maps, availableActions, opts }: Params) =>
    ({ get }) => {
      const deepLinksMap = get(deepLinks);
      const actions = get(flowActions);
      const action = actions.find(({ id }) => id === actionId);

      const updatedRelativeMaps = getUpToDateRelativeMaps({
        action,
        actions,
        maps,
      });

      if (!action) return [];

      const issues = getIssuesForAction({
        action,
        relativeMaps: updatedRelativeMaps,
        availableActions,
        opts,
        deepLinksMap: deepLinksMap,
      });

      return issues;
    },
});

/**
 * Returns issues for the given action
 */
export const issuesByActionId = selectorFamily<Array<Issue>, string>({
  key: 'issuesByActionId',
  get:
    (actionId: string) =>
    ({ get }) => {
      const issues = get(flowIssues);

      return issues[actionId] ?? [];
    },
});

export default flowIssues;
