import getInstanceForCondition from '~/components/page/Automation/v2/state/flowInstance/utils/getInstanceForCondition';
import { extendDirectoryMap } from '../../components/Selector/utils/getDirectory';
import { extendInstanceMap } from '../../components/Selector/utils/getInstance';
import type { RelativeMaps } from '~/components/page/Automation/v2/types';
import type { Flow___ConditionPartial } from '../../components/ConditionEditorV2/types';

type Args = {
  /** ActionId where this condition resides in - for pointer generation */
  actionId: string;
  /** The trigger condition within the condition expression */
  triggerCondition: Flow___ConditionPartial;
  /** The default relative maps */
  maps: RelativeMaps;
};

/**
 * Generates the relative lookup maps for a given trigger.
 *
 * Subconditions can then e.g. do "The email in this condition" - "should be opened"
 * also known as "condition on self"
 */
const generateRelativeMaps = ({
  triggerCondition,
  actionId,
  maps,
}: Args): RelativeMaps => {
  const triggerInstance = getInstanceForCondition(
    { condition: triggerCondition, actionId },
    maps,
  );

  if (triggerInstance == null) return maps;

  const relativeInstanceMap = extendInstanceMap(maps.instanceMap, [
    triggerInstance,
  ]);

  const relativeDirectoryMap = extendDirectoryMap(
    maps.directoryMap,
    {
      instances: [triggerInstance],
    },
    maps.subjectMap,
  );

  return {
    ...maps,
    instanceMap: relativeInstanceMap,
    directoryMap: relativeDirectoryMap,
  };
};

export default generateRelativeMaps;
