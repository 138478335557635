import type { DeepLinkElement } from '~/components/organism/PluginsEditor/types';

/**
 * serializes the DeepLink element into html element
 * @param {DeepLinkElement} node - DeepLink element
 * @param {string} children - Children
 */
const serialize = (node: DeepLinkElement): string => {
  if (!node || !node.mappingId) return '';

  return `_%${node.mappingId}%_`;
};

export default serialize;
