import { clone, isNil } from 'ramda';
import { useCallback } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import onUpdateActions from '~/components/page/Automation/v2/components/Builder/components/edgeTypes/utils/onUpdateActions';
import useBuilderContext from '~/components/page/Automation/v2/components/Builder/hooks/useBuilderContext';
import { actionsSelector } from '~/components/page/Automation/v2/state';
import dialogs from '~/components/page/Automation/v2/state/dialogs';
import interactions from '~/components/page/Automation/v2/state/interactions';
import { FlowAction } from '~/graphql/types';
import type { ClientFlowAction } from '~/graphql/types.client';
import isEmptyNode from '../../utils/isEmptyNode';
import resetAndCloneAction from '../../utils/resetAndCloneAction';
import {
  HandlerId,
  PathType,
} from '../../components/nodeTypes/components/IfElseCard/types';
import updateDeepLinkIds from '../../utils/updateDeepLinkIds';

type Params = {
  // parent id where we are pasting
  source: string;

  // child id where we are pasting
  target: string;

  // for the empty nodes in IfElse action, which path we should assign it to
  sourceKey?: HandlerId;
};

const usePasteAction = ({
  source,
  target,
  sourceKey,
}: Params): {
  onInsertAction: () => void;
  onConfirm: (args: { path: PathType; newAction: ClientFlowAction }) => void;
} => {
  const { flowBlueprintId, accountId } = useBuilderContext();
  const currentInteraction = useRecoilValue(interactions);
  const setDialog = useSetRecoilState(dialogs);

  const [actions, setActions] = useRecoilState(
    actionsSelector({
      accountId,
      flowBlueprintId,
    }),
  );

  const copiedAction =
    currentInteraction?.type === 'copyAction'
      ? currentInteraction.copiedAction
      : null;

  const newAction: ClientFlowAction | null = resetAndCloneAction({
    action: copiedAction,
    source,
  });

  const onConfirm = useCallback(
    ({
      path,
      newAction,
    }: {
      path?: PathType;
      newAction: ClientFlowAction | null;
    }) => {
      const mutableNewAction = clone(newAction);

      if (
        mutableNewAction?.__typename === 'FlowV2_Action_SendEmail_Plain' &&
        mutableNewAction.deepLinks.length > 0
      ) {
        updateDeepLinkIds({ mutableNewAction });
      }

      if (isNil(mutableNewAction)) return;

      if (isEmptyNode(target)) {
        // only update the parent and add the new action
        const mutableActions = [...actions];
        const parentActionIndex = mutableActions.findIndex(
          action => action.id === source,
        );
        const parentAction = mutableActions[parentActionIndex];
        const updatedParentAction = {
          ...parentAction,
        };

        if (sourceKey) {
          const childIdKey = `${sourceKey}Id`;
          updatedParentAction[childIdKey] = mutableNewAction.id;
        }

        mutableActions[parentActionIndex] = updatedParentAction;

        return setActions([...mutableActions, mutableNewAction]);
      } else {
        setActions(actions =>
          onUpdateActions(actions, {
            source,
            target,
            subject: mutableNewAction,
            isPasteTruePath:
              path === PathType.True
                ? true
                : path === PathType.False
                  ? false
                  : undefined,
            keepPrevParentsOfTarget: true,
          }),
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [actions, sourceKey, source, target],
  );

  const onInsertAction = useCallback(() => {
    if (isNil(copiedAction) || isNil(newAction)) return;

    if (newAction.actionType === FlowAction.IfElse && !isEmptyNode(target)) {
      setDialog({
        type: 'dialogSelectIfElsePathOnPaste',
        source,
        target,
        newAction,
      });
    } else {
      onConfirm({ newAction });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [copiedAction, newAction, source, target, onConfirm]);

  return { onInsertAction, onConfirm };
};

export default usePasteAction;
