import { uniqBy } from 'ramda';
import {
  DirectoryMap,
  extendDirectoryMap,
} from '~/components/page/Automation/v2/components/UpdateAction/components/Selector/utils/getDirectory';
import {
  generateInstanceMap,
  InstanceMap,
} from '~/components/page/Automation/v2/components/UpdateAction/components/Selector/utils/getInstance';
import getInstanceForCondition from '~/components/page/Automation/v2/state/flowInstance/utils/getInstanceForCondition';

import type { Flow___ConditionPartial } from '~/components/page/Automation/v2/components/UpdateAction/components/ConditionEditorV2/types';
import type { RelativeMaps } from '~/components/page/Automation/v2/types';

type ExtendRelativeMapsArgs = {
  /** The action */
  actionId: string;
  /** Extends the current relative maps with the instance of the given condition  */
  condition: Flow___ConditionPartial | null;
  /** Relative maps to be extended */
  maps: RelativeMaps;
};
export const extendRelativeMaps = ({
  actionId,
  condition,
  maps,
}: ExtendRelativeMapsArgs) => {
  const extraInstances = (() => {
    if (!condition) return [];

    const instance = getInstanceForCondition(
      { actionId, condition },
      { subjectMap: maps.subjectMap },
    );

    if (!instance) return [];

    return [instance];
  })();

  const relativeInstances = uniqBy(
    item => item.key.join(';;'),
    maps.instances.concat(extraInstances),
  );

  const relativeInstanceMap = relativeInstances
    ? generateInstanceMap(relativeInstances)
    : {};

  const completeInstanceMap: InstanceMap = {
    ...maps.instanceMap,
    ...relativeInstanceMap,
  };

  const relativeDirectoryMap: DirectoryMap = extendDirectoryMap(
    maps.directoryMap,
    {
      instances: relativeInstances,
    },
    maps.subjectMap,
  );

  return {
    ...maps,
    instanceMap: completeInstanceMap,
    directoryMap: { ...maps.directoryMap, ...relativeDirectoryMap },
  };
};

export default extendRelativeMaps;
