import { isEmpty, isNil } from 'ramda';
import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { componentSizes, Size } from '~/styles/constants';
import { SystemSize } from '~/theme';
import arrayToCss from '~/util/arrayToCss';
import { IconType } from '~/components/atom/Icon';
import JustificationContainer from '~/components/atom/JustificationContainer';
import DropdownListContainer from './components/DropdownListContainer';
import getDropdownAppearances from './getDropdownAppearances';
import TEST_ID from './index.testid';
import getOptionType from './utils/getOptionType/index';
import InputLabel from '~/components/atom/InputLabel';
import type { ThemeColor } from '~/theme/System/tokens/colorPalette';
import IconFacade from './components/IconFacade';
import getMaxHeight from '~/util/getMaxHeight';

export type OptionBase = {
  label: React.ReactNode;
  key: string | number;
  icon?: { name: IconType; color?: ThemeColor };
  tooltipMessage?: string | React.ReactNode;
  /**
   * An optional description that may be shown
   */
  description?: React.ReactNode;
  isValid?: boolean;
  onClickAction?: (e?: React.SyntheticEvent<any>) => void;
  isEmptySelectionOption?: boolean;
  styleOptions?: {
    lineAbove?: boolean;
  };

  /** Styling will change based on the type */
  type?: 'DANGER' | 'DISABLED' | null;
  dataTestId?: string;
};
export type Option = OptionBase & {
  payload: any;
};
export type OptionOf<T> = OptionBase & {
  payload: T;
};

export type SelectedOptionOf<T> = {
  option: OptionOf<T>;
  selectedOptionIdx: number;
};
export type SelectedOption = {
  option: Option;
  selectedOptionIdx: number;
};
export type OnChangeFunction = (option: SelectedOptionOf<any>) => void;
export type OnChangeFunctionOf<T> = (option: SelectedOptionOf<T>) => void;

export type Appearance =
  | 'outline'
  | 'borderless'
  | 'filled'
  | 'default'
  | 'accent';

export type Props = {
  dataTestId?: string;

  className?: string;

  /** Small, medium or large dropdown, defaults to medium. */
  size?: Size;

  /** Disable Dropdown. */
  disabled?: boolean;

  /** The options to display. */
  options: Array<Option>;

  /** Error, this replaces the label if its set. */
  error?: string | null;

  /** The index of the selected option. */
  selectedOptionIdx?: number | null;

  /** The Label to display above the dropdown. */
  label?: React.ReactNode;

  appearance?: Appearance;

  /** An array of margins for the dropdown base. */
  margin?: Array<SystemSize | null>;

  /** Callback, fired after an option was selected. */
  onChange: OnChangeFunction;

  /** An action to do when clicking outside the dropdown. */
  onClickOutside?: () => void;

  /** If the dropdown should initially select the only option if nothing is selected. */
  selectOnlyOptionAutomatically?: boolean;

  /** If the first option should be selected when nothing is selected, defaults to true. */
  withDefaultFirstOption?: boolean;

  /** Default loading state for dropdown list. */
  loading?: boolean;

  /** Exceptional loading state, shown in dropdown label. Used when the dropdown list cannot be open */
  labelLoading?: boolean;

  placeholder?: string;

  /** Tab index order */
  tabIndex?: string;

  /** Width of the dropdown. Default is 100% */
  width?: string;

  required?: boolean;

  icon?: IconType | null;
};

const DEFAULT_LABEL = 'Kies een optie';

const Dropdown: React.FCC<Props> = ({
  dataTestId,
  className,
  size = 'medium',
  disabled = false,
  options,
  error,
  selectedOptionIdx,
  onChange,
  appearance = 'default',
  label,
  margin = [null, null, null, null],
  onClickOutside,
  selectOnlyOptionAutomatically,
  withDefaultFirstOption = true,
  loading,
  labelLoading,
  placeholder,
  icon = 'chevron',
  tabIndex,
  width = '100%',
  required = false,
}) => {
  const [dropdownListOpen, setDropdownListOpen] = useState<boolean>(false);
  const errorInOption = getOptionType(options, selectedOptionIdx) === 'DANGER';
  const hasError = (!isNil(error) && !isEmpty(error)) || errorInOption;

  const dropdownBaseRef = useRef<HTMLDivElement | null>(null);
  const dropdownWidth: number | undefined =
    dropdownBaseRef.current?.clientWidth;

  useEffect(() => {
    if (
      selectOnlyOptionAutomatically &&
      options.length === 1 &&
      (selectedOptionIdx == null || selectedOptionIdx < 0) &&
      onChange
    ) {
      onChange({ option: options[0], selectedOptionIdx: 0 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  const onClose = () => setDropdownListOpen(false);

  const selectedOption =
    selectedOptionIdx != null ? options[selectedOptionIdx] : null;

  const labelToShow = selectedOption?.label ?? placeholder ?? DEFAULT_LABEL;

  return (
    <Container
      direction="column"
      margin={margin}
      data-testid={dataTestId}
      $width={width}
      className={className}
    >
      <InputLabel
        required={required}
        error={hasError ? error : undefined}
        label={label}
        size={size}
      />

      <DropdownBase
        tabIndex={tabIndex ? parseInt(tabIndex) : undefined}
        ref={dropdownBaseRef}
        data-testid={TEST_ID.DROPDOWN_TOGGLE}
        data-objectid={selectedOption && selectedOption.key}
        $size={size}
        $appearance={appearance}
        $disabled={disabled}
        $error={hasError}
        $isOpen={dropdownListOpen}
        $withIcon={!isNil(icon)}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();

          !disabled && setDropdownListOpen(prev => !prev);
        }}
      >
        <OptionLabel $size={size} id="selected-option-label">
          {labelToShow}
        </OptionLabel>

        <IconFacade
          loading={labelLoading}
          dropdownListOpen={dropdownListOpen}
          icon={icon}
        />
      </DropdownBase>

      <DropdownListContainer
        onClose={onClose}
        dropdownWidth={dropdownWidth}
        withDefaultFirstOption={withDefaultFirstOption}
        selectedOptionIdx={selectedOptionIdx}
        options={options}
        loading={loading}
        onChange={option => {
          onChange(option);
          onClose();
        }}
        dropdownListOpen={dropdownListOpen}
        onClickOutside={() => {
          onClose();
          onClickOutside && onClickOutside();
        }}
        openerRef={dropdownBaseRef}
      />
    </Container>
  );
};

const Container = styled(JustificationContainer)<{ $width: string }>(
  ({ theme, $width }) => css`
    cursor: pointer;
    width: ${$width};
    line-height: ${theme.lineHeight('s')};
  `,
);

type BaseDropdownProps = {
  $size: Size;
  $disabled?: boolean;
  $error?: boolean;
  $appearance: Appearance;
  $isOpen?: boolean;
  $withIcon?: boolean;
};

const OptionLabel = styled.span<{ $size: Size }>(
  ({ theme, $size }) => css`
    display: block;
    margin-right: ${theme.space('xxs')};
    font-weight: ${theme.fontWeight('regular')};
    font-size: ${theme.fontSize(componentSizes[$size].fontSize)};

    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-bottom: ${theme.space('xxxs')};
  `,
);

const DropdownBase = styled.div<BaseDropdownProps>(
  ({
    theme,
    $size,
    $disabled,
    $error,
    $appearance,
    $isOpen,
    $withIcon = true,
  }) => {
    const dropdownAppearances = getDropdownAppearances(theme);

    const colorAndBackground =
      $error && $isOpen
        ? dropdownAppearances[$appearance].error.hover
        : $error
          ? dropdownAppearances[$appearance].error.base
          : $disabled
            ? dropdownAppearances[$appearance].disabled.base
            : $isOpen
              ? dropdownAppearances[$appearance].main.hover
              : dropdownAppearances[$appearance].main.base;

    const hoverStyles =
      !$disabled &&
      ($error
        ? dropdownAppearances[$appearance].error.hover
        : dropdownAppearances[$appearance].main.hover);

    const icon = dropdownAppearances[$appearance].icon;

    return css`
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: ${$withIcon ? 'space-between' : 'center'};

      /**
      * When the selected option has an icon inside, the height of the dropdown grows unwantedly.
      * Here we set a fixed height for it to prevent that so that all our inputs and dropdowns have the same height.
      *
      * Max-height is calculated by:
      * calc(padding top + padding bottom + font size + border top + border bottom)
      */
      ${getMaxHeight({
        paddingY: theme.space(componentSizes[$size].padding[0]),
        fontSize: theme.fontSize(componentSizes[$size].fontSize),
        borderWidth: theme.getTokens().border.width.s,
      })}

      user-select: none;
      font-weight: ${theme.fw('medium')};
      cursor: ${$disabled ? 'not-allowed' : 'pointer'};

      border-radius: ${theme.getTokens().border.radius.base};
      padding: ${arrayToCss(componentSizes[$size].padding, theme)};

      ${$appearance === 'borderless' &&
      css`
        padding-left: 0;
      `};

      transition:
        all 0.3s,
        color 0.3s;

      ${colorAndBackground}

      svg {
        color: ${$disabled
          ? colorAndBackground?.color || icon.base
          : icon.base};
        font-size: ${theme.fontSize(componentSizes[$size].fontSize)};
      }

      &:hover {
        ${hoverStyles}

        svg {
          color: ${($disabled ? colorAndBackground?.color : icon.hover) ||
          icon.hover};
        }
      }
    `;
  },
);

export default Dropdown;
