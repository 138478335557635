import React, { useEffect, useRef, useState } from 'react';
import { useSlate } from 'slate-react';
import { tooltipText } from '../text';
import useDeepLinkConfigs from '~/hooks/useDeepLinkConfigs';
import { insertDeeplink } from '../../../commands/modify/deeplink';
import BaseButton from '../components/Base';
import Dropdown from '~/components/molecule/Dropdown';
import ResponsivePopup, {
  BoxShadowContainer,
} from '~/components/molecule/ResponsivePopup';
import Input from '~/components/molecule/Input';
import Button from '~/components/atom/Button';
import { Editor } from 'slate';
import type { DeepLink_ConfigFragment } from '~/graphql/types';
import { isNonEmptyString } from '~/util/Validation/String';
import { Body, Heading3, Label } from '~/components/atom/Typography';
import JustificationContainer from '~/components/atom/JustificationContainer';
import Catalog from '~/Catalog';
import isConfigEqual from '../../../utils/deepLinks/isConfigEqual';
import { isNil } from 'ramda';
import useGlobalKeyBinding from '~/hooks/useGlobalKeyBinding';

const text = {
  label: 'App link invoegen',
  inputLabel: 'Linktekst',
  heading: 'App link invoegen',
  insert: 'Toevoegen',
  dropdownLabel: 'App link',
  dropdownPlaceholder: 'App link selecteren',
  required: Catalog.requiredField,
  inputPlaceholder: 'Klik deze link',
  description:
    'Voeg hier een link toe naar een app of formulier. De link vult voor jouw contact automatisch de contactgegevens in.',
};

export type Props = {};

const InsertDeepLinkButton: React.FCC<Props> = ({ dataTestId }) => {
  const editor = useSlate();

  const baseButtonRef = useRef<HTMLButtonElement | null>(null);

  const { configOptions } = useDeepLinkConfigs();

  const selectedText = editor.selection
    ? Editor.string(editor, editor.selection)
    : '';

  const [showPopup, setShowPopup] = useState(false);
  const [deepLink, setDeepLink] = useState<DeepLink_ConfigFragment | null>(
    null,
  );
  const [linkText, setLinkText] = useState<string>(selectedText);

  useEffect(() => {
    setLinkText(selectedText);
  }, [selectedText]);

  const onReset = () => {
    setShowPopup(false);
    setDeepLink(null);
    setLinkText('');
  };

  const onClickOutside = (e: MouseEvent) => {
    // if click is on dropdown option allow it to be clicked
    const dropdownRoot = document.getElementById('dropdown-portal-root');
    if (
      e.target &&
      e.target instanceof Node &&
      dropdownRoot &&
      dropdownRoot.contains(e.target)
    )
      return;
    onReset();
  };

  const disabled = isNil(deepLink) || linkText === '';

  const onInsert = () => {
    if (disabled || !deepLink) return;
    insertDeeplink({ editor, params: deepLink, linkText });
    onReset();
  };

  useGlobalKeyBinding({
    keys: 'enter',
    callback: () => onInsert(),
    enabled: !disabled,
  });

  return (
    <>
      <BaseButton
        active={showPopup}
        icon="anchor"
        tooltipMessage={tooltipText.insertDeepLink}
        dataTestId={dataTestId}
        onClick={() => setShowPopup(true)}
        ref={baseButtonRef}
      >
        <Label margin={[null, null, null, 'xxs']} fontWeight="medium">
          {text.label}
        </Label>
      </BaseButton>
      <ResponsivePopup
        onClickOutside={onClickOutside}
        isVisible={showPopup}
        openerRef={baseButtonRef.current}
        rootId="hovering-toolbar"
      >
        <BoxShadowContainer $width="400px">
          <JustificationContainer direction="column" gap="s">
            <Heading3 size="base" margin={[null]}>
              {text.heading}
            </Heading3>
            <Body size="base" margin={[null]}>
              {text.description}
            </Body>
            <Dropdown
              options={configOptions}
              onChange={({ option }) => setDeepLink(option.payload)}
              label={text.dropdownLabel}
              selectedOptionIdx={configOptions.findIndex(option =>
                isConfigEqual({
                  configItem: option.payload,
                  item: {
                    type: deepLink?.type,
                    typeId: deepLink?.typeId,
                  },
                }),
              )}
            />
            <Input
              onChange={e => {
                setLinkText(e.target.value || '');
              }}
              validation={[
                (value: string) =>
                  isNonEmptyString(value) ? true : text.required,
              ]}
              label={{ text: text.inputLabel }}
              placeholder={text.inputPlaceholder}
              value={linkText}
              width="100%"
            />
            <Button
              onClick={onInsert}
              label={text.insert}
              style={{ alignSelf: 'end' }}
              disabled={disabled}
            />
          </JustificationContainer>
        </BoxShadowContainer>
      </ResponsivePopup>
    </>
  );
};

export default InsertDeepLinkButton;
