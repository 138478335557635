import type { DeepLink_ConfigFragment } from '~/graphql/types';

/**
 * Compare the type and typeIds to return the matching deep link config for a deep link
 * @param {DeepLink_ConfigFragment} configItem - Deep link config item
 * @param {{ type: string | undefined; typeId: string | undefined | null }} item - Deep link item to compare it with.
 * Could be a DeepLinkFragment or DeepLinkElement
 *
 * keywords: configtolinkcomparison
 */
const isConfigEqual = ({
  configItem,
  item,
}: {
  configItem: DeepLink_ConfigFragment;
  item: { type: string | undefined; typeId: string | undefined | null };
}) => configItem.type === item.type && configItem.typeId === item.typeId;

export default isConfigEqual;
