import type { RelativeMaps } from '~/components/page/Automation/v2/types';
import { getCondition } from '~/components/page/Automation/v2/components/UpdateAction/components/Selector/utils/getConditions';
import getSubject from '~/components/page/Automation/v2/components/UpdateAction/components/Selector/utils/getSubject';
import type { Flow___ConditionPartial } from '../../types';

type Args = {
  /** The condition to get the flow data for */
  condition: Flow___ConditionPartial;
  /** The relative maps for this condition */
  maps: RelativeMaps;
};

/**
 * Returns the flow data for a given condition.
 */
const getFlowDataForCondition = ({ condition, maps }: Args) => {
  const flowData = (() => {
    switch (condition.__typename) {
      case 'Flow___InstanceCondition':
        return getCondition(condition.type, maps.conditionMap) ?? null;
      case 'Flow___SubjectFieldCondition':
        return (
          getSubject(
            { type: condition.type, typeId: condition.typeId },
            maps.subjectMap,
          )?.fields.find(({ key }) => condition.field === key) ?? null
        );
    }
  })();

  if (!flowData) return null;

  const firstArgumentIndex = flowData.representation.findIndex(
    ({ variable }) => !!variable,
  );

  const lastArgumentIndex = [...flowData.representation]
    .reverse()
    .findIndex(({ variable }) => !!variable);

  const hasNoArguments = firstArgumentIndex === -1;
  const argumentsEndIndex = flowData.representation.length - lastArgumentIndex;

  if (hasNoArguments) return { hasArguments: false, flowData };

  return {
    hasArguments: true,
    flowData,
    firstArgumentIndex,
    lastArgumentIndex: argumentsEndIndex,
  };
};

export default getFlowDataForCondition;
