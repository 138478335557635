import { atom, selectorFamily } from 'recoil';
import { IBuilderContext } from '../components/Builder/context';

import { ClientFlowAction } from '~/graphql/types.client';
import { getActionTemplate } from '../components/Builder/constants/actionTemplates';
import { FlowAction } from '~/graphql/types';
import getLabelledActions from '../util/getLabelledActions';

export const lastAddedActionId = atom<string | null>({
  key: 'lastAddedFlowActionId',
  default: null,
});

const flowActions = atom<Array<ClientFlowAction>>({
  key: 'flowActions',
  default: [],
});

export const actionById = selectorFamily({
  key: 'actionById',
  get:
    (actionId: string | null) =>
    ({ get }): ClientFlowAction | undefined => {
      if (actionId === null) return;

      const actions = get(flowActions);
      return actions.find(({ id }) => id === actionId);
    },
});

export const actionsSelector = selectorFamily({
  key: 'actionsSelector',
  get:
    ({
      accountId,
      flowBlueprintId,
    }: {
      accountId: IBuilderContext['accountId'];
      flowBlueprintId: IBuilderContext['flowBlueprintId'];
    }) =>
    ({ get }) => {
      const actions = get(flowActions);
      if (actions.length === 0) {
        const generatedStartAction = getActionTemplate({
          flowBlueprintId: flowBlueprintId,
          actionType: FlowAction.Start,
          accountId: accountId,
        });
        // This expression is only there to keep Typescript happy.
        // There will always be start action from this call
        return generatedStartAction !== null ? [generatedStartAction] : [];
      }

      return getLabelledActions(actions);
    },

  set:
    (_builderContext: IBuilderContext) =>
    ({ set, get }, newValue: Array<ClientFlowAction>) => {
      // Compare the prev state to current state and return the last added action
      const prevActions = get(flowActions);
      const lastAddedAction = newValue.find(
        currentAction =>
          !prevActions.some(prevAction => prevAction.id === currentAction.id),
      );

      set(lastAddedActionId, lastAddedAction?.id);
      set(flowActions, newValue);
    },
});

export default flowActions;
