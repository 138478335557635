import type {
  DeepLink_ConfigFragment,
  DeepLinkFragment,
} from '~/graphql/types';
import { ClientFlowAction } from '~/graphql/types.client';
import type { DeepLinksMap } from '../../../../state/deepLinks';

/**
 * Returns the current deep links that exist in the flow builder
 * @param {Array<DeepLink_ConfigFragment>} deepLinkConfigs - Example argument
 * @param {Array<ClientFlowAction>} actions - Actions
 */
const getDeepLinksMap = ({
  deepLinkConfigs,
  actions,
}: {
  deepLinkConfigs: Array<DeepLink_ConfigFragment>;
  actions: Array<ClientFlowAction>;
}): DeepLinksMap => {
  const allDeepLinks = actions?.reduce((prev, curr) => {
    if (curr.__typename === 'FlowV2_Action_SendEmail_Plain') {
      prev.push(...(curr.deepLinks || []));
    }

    return prev;
  }, [] as Array<DeepLinkFragment>);

  return (
    allDeepLinks?.reduce((prev, curr) => {
      const matchingDeepLink = deepLinkConfigs?.find(
        config =>
          config.type === curr.link.type && config.typeId === curr.link.typeId,
      );

      if (matchingDeepLink) {
        prev[curr.id] = {
          label: `${curr.text.template} (${matchingDeepLink.name})`,
        };
      }
      return prev;
    }, {}) || {}
  );
};

export default getDeepLinksMap;
