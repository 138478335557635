import React from 'react';
import { RenderElementProps, useFocused, useSelected } from 'slate-react';
import styled, { css } from 'styled-components';
import { LinkElement } from '~/components/organism/PluginsEditor/types';
import ELEMENTS from '~/components/organism/PluginsEditor/components/elements/elementsEnum';
import serialize from './serialize';
import TEST_ID from './index.testid';

export type Props = RenderElementProps & {
  element: LinkElement;
};

// Put this at the start and end of an inline component to work around this Chromium bug:
// https://bugs.chromium.org/p/chromium/issues/detail?id=1249405
const InlineChromiumBugfix = () => (
  <span contentEditable={false} style={{ fontSize: '0' }}>
    ${String.fromCodePoint(160) /* Non-breaking space */}
  </span>
);

const Link: React.FCC<Props> = ({ attributes, children, element }) => {
  const selected = useSelected();
  const focused = useFocused();
  const active = selected && focused;

  return (
    <Container
      {...attributes}
      {...element.attributes}
      style={{
        ...element?.attributes?.style,
        display: 'inline',
      }}
      href={element.url}
      data-testid={TEST_ID.CONTAINER}
      onClick={e => e.stopPropagation()}
      $active={active}
    >
      <InlineChromiumBugfix />
      {children}
      <InlineChromiumBugfix />
    </Container>
  );
};

const Container = styled.a<{ $active: boolean }>(
  ({ theme, $active }) => css`
    font-weight: ${$active ? theme.fontWeight('semiBold') : 'auto'};
  `,
);

export default {
  nodeName: 'A',
  renderComponent: props => <Link {...props} />,
  deserialize: el => ({
    type: ELEMENTS.LINK,
    url: el.getAttribute('href'),
  }),
  serialize,
};
