import React from 'react';
import styled, { css } from 'styled-components';
import { Size, componentSizes } from '~/styles/constants';
import { isEmpty, isNil } from 'ramda';
import { Label, Props as TypographyProps } from '../Typography';
import TEST_ID from './index.testid';
import withRequiredMark from '~/hocs/withRequiredMark';
import type { ThemeColor } from '~/theme/System/tokens/colorPalette';

export type Props = {
  /** Label to be displayed if it is passed */
  label?: string | React.ReactNode;

  /** Error message that replaces the label if it is passed */
  error?: string | null;

  /** Size of the label */
  size?: Size;

  required?: boolean;

  color?: ThemeColor;
  margin?: TypographyProps['margin'];
};

/**
 * Label used in inputs and dropdowns
 */
const InputLabel: React.FCC<Props> = ({
  error,
  label,
  size = 'medium',
  required = false,
  color,
  margin,
  ...rest
}) => {
  const hasError = !isNil(error) && !isEmpty(error);

  const Comp = required === true ? WithRequiredMark : StyledLabel;

  return (
    <>
      {label && (
        <Comp
          inline
          color={color ?? { group: 'text' }}
          $size={size}
          margin={margin || [null, null, 'xxxs', null]}
          dataTestId={TEST_ID.LABEL}
          {...rest}
        >
          {label}&nbsp;
          {hasError && (
            <StyledLabel
              wordBreak="break-all"
              inline
              color={{ group: 'danger' }}
              $size={size}
              dataTestId={TEST_ID.ERROR_LABEL}
              {...rest}
            >
              ({error})
            </StyledLabel>
          )}
        </Comp>
      )}
    </>
  );

  return null;
};

type LabelProps = TypographyProps & {
  $size: Size;
  $hasError?: boolean;
};

const WithRequiredMark = withRequiredMark<LabelProps>(props => (
  <StyledLabel {...props} />
));

const StyledLabel = styled(Label)<LabelProps>(
  ({ $size = 'medium', theme }) => css`
    font-size: ${theme.fontSize(componentSizes[$size].fontSize)};
    font-weight: ${theme.fw('semiBold')};
  `,
);

export default InputLabel;
