import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import Overlay from '../ModalsV2/Overlay';
import Modal from '../ModalsV2/Modal';
import getImageSrcFromFileList, {
  type LoadedImageData,
} from './utils/getImageSrcFromFileList';
import {
  Cropper,
  type CropperRef,
  type StencilSize,
} from 'react-advanced-cropper';
import 'react-advanced-cropper/dist/style.css';
import Button from '~/components/atom/Button';
import dataURLToFile from './utils/dataURLToFile';
import getDataUrlWithMaxFileSize from './utils/getDataUrlWithMaxFileSize';
import Toolbar from './components/Toolbar';
import Loading from '~/components/atom/Loading';
import JustificationContainer from '~/components/atom/JustificationContainer';
import TextButton from '~/components/atom/TextButton';
import Catalog from '~/Catalog';
import TEST_ID from './index.testid';

const text = {
  confirmLabel: 'Opslaan',
};

// 10 MB
const MAX_SIZE = 10000000;

export type TargetImageDimensions = StencilSize;

export type Props = {
  fileList: FileList;
  onClose?: () => void;
  onConfirm: (image: File) => void;
};

const ImageEditorModal: React.FCC<Props> = ({
  onClose,
  fileList,
  onConfirm,
}) => {
  const cropperRef = useRef<CropperRef>(null);
  const [safeToMountImage, setSafeToMountImage] = useState(false);
  const [imageData, setImageData] = useState<null | LoadedImageData>(null);

  useEffect(() => {
    if (safeToMountImage) {
      void getImageSrcFromFileList(fileList)
        .then(data => {
          setImageData(data);
        })
        .catch(err => {
          // eslint-disable-next-line no-console
          console.error('Error happened trying to load image into editor', err);
        });
    }
  }, [fileList, safeToMountImage]);

  if (!safeToMountImage || !imageData) {
    return (
      <Overlay onClose={onClose} root="image-editor-modal">
        <Modal
          onAnimationRest={() => {
            setSafeToMountImage(true);
          }}
        >
          <Container>
            <JustificationContainer
              justification="center"
              align="center"
              height="60vh"
            >
              <Loading />
            </JustificationContainer>
          </Container>
        </Modal>
      </Overlay>
    );
  }

  return (
    <Overlay onClose={onClose} root="image-editor-modal">
      <Modal>
        <Container data-testid={TEST_ID.CONTAINER}>
          <CropperContainer>
            <Cropper
              ref={cropperRef}
              src={imageData?.src}
              className={'cropper'}
              defaultCoordinates={{
                ...imageData.intrinsicSize,
                top: 0,
                left: 0,
              }}
            />
            <Toolbar
              onFlip={dir => {
                cropperRef.current?.flipImage(dir === 'x', dir === 'y');
              }}
              onRotate={dir => {
                cropperRef.current?.rotateImage(dir === 'clockwise' ? 90 : -90);
              }}
              onMaximize={() => {
                cropperRef.current?.setCoordinates({
                  ...imageData.intrinsicSize,
                  top: 0,
                  left: 0,
                });
                cropperRef.current?.zoomImage(0.5);
              }}
              onZoomIn={() => {
                cropperRef.current?.zoomImage(2);
              }}
              onZoomOut={() => {
                cropperRef.current?.zoomImage(0.5);
              }}
            />
          </CropperContainer>
          <Footer justification="space-between" align="center">
            <TextButton
              label={Catalog.cancel}
              onClick={onClose}
              appearance="accent"
            />
            <Button
              dataTestId={TEST_ID.SAVE_BUTTON}
              label={text.confirmLabel}
              icon="save"
              onClick={async () => {
                const dataUrl = await getDataUrlWithMaxFileSize(
                  cropperRef,
                  MAX_SIZE,
                );

                if (dataUrl && onConfirm) {
                  onConfirm(dataURLToFile(dataUrl, fileList[0].name));
                }
              }}
            />
          </Footer>
        </Container>
      </Modal>
    </Overlay>
  );
};

const Container = styled.div<{}>(
  ({ theme }) => css`
    padding: ${theme.space('m')};
    max-height: 100%;
    min-height: 60vh;
    position: relative;
  `,
);

const CropperContainer = styled.div(
  () => css`
    position: relative;
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    .cropper {
      max-width: 100%;
      max-height: 60vh;
      background-color: transparent;
    }
  `,
);

const Footer = styled(JustificationContainer)(
  ({ theme }) => css`
    position: absolute;
    bottom: ${theme.space('m')};
    right: ${theme.space('m')};
    left: ${theme.space('m')};
  `,
);

export default ImageEditorModal;
