import { Transforms } from 'slate';
import { ReactEditor } from 'slate-react';
import ELEMENTS from '~/components/organism/PluginsEditor/components/elements/elementsEnum';
import type { DeepLinkElement, DHEditor } from '../../../types';
import type { DeepLink_ConfigFragment } from '~/graphql/types';
import convertDeepLinkConfigMappingToMapping from '../../../utils/deepLinks/convertDeepLinkConfigMappingToMapping';
import generateIdForEntity from '~/util/generateIdForEntity';

export const insertDeeplink = ({
  editor,
  params,
  linkText,
}: {
  editor: DHEditor;
  params: DeepLink_ConfigFragment;
  linkText: string;
}) => {
  const { name, type, typeId, mapping } = params;

  const deepLinkElement: DeepLinkElement = {
    type: ELEMENTS.DEEP_LINK,
    deeplinkText: linkText,
    deeplinkConfigName: name,
    children: [{ text: '' }],
    mappingId: generateIdForEntity('DEEP_LINK'),
    deeplinkType: type,
    deeplinkTypeId: typeId,
    mapping: convertDeepLinkConfigMappingToMapping(mapping),
  };

  Transforms.insertNodes(editor, deepLinkElement);
  ReactEditor.focus(editor);

  // Move cursor so that it does not focus on the inserted deep link
  Transforms.move(editor, {
    distance: 1,
    unit: 'offset',
  });
};

/** If you are editing the DeepLink */
export const updateDeepLink = ({
  editor,
  updatedFields,
}: {
  editor: DHEditor;
  updatedFields: Omit<DeepLinkElement, 'type' | 'children'>;
}) => {
  Transforms.insertNodes(editor, {
    type: ELEMENTS.DEEP_LINK,
    children: [{ text: '' }],
    ...updatedFields,
  });
  ReactEditor.focus(editor);
};
