import type { OptionOf } from '~/components/molecule/Dropdown';
import { SessionHydrationOfficeFieldsFragment } from '~/graphql/types';
import useOffices from '../useOffices';

type Filter = {
  userId?: string | null;
  withAllOfficesOption?: boolean;
};

export type OfficeOptions = Array<
  OptionOf<SessionHydrationOfficeFieldsFragment | null>
>;

const allOfficesOption = {
  label: 'Alle vestigingen',
  key: 'no-selection',
  payload: null,
};

const useOfficeOptions = ({
  userId = null,
  withAllOfficesOption = false,
}: Filter = {}): OfficeOptions => {
  const officesForUser = useOffices({ userIds: [userId] });
  const allOffices = useOffices({ userIds: [] });

  const options =
    userId && officesForUser.length > 0
      ? mapOfficesToOptions(officesForUser)
      : mapOfficesToOptions(allOffices);

  return withAllOfficesOption ? addAllOfficesOption(options) : options;
};

const addAllOfficesOption = (options: OfficeOptions): OfficeOptions => [
  allOfficesOption,
  ...options,
];

const mapOfficesToOptions = (
  offices: Array<SessionHydrationOfficeFieldsFragment>,
): OfficeOptions =>
  offices
    .map(office => ({
      key: office.id,
      label: office.name,
      payload: office,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

export default useOfficeOptions;
