import { uniqBy, unnest } from 'ramda';
import { Element, Text } from 'slate';
import ELEMENTS from '~/components/organism/PluginsEditor/components/elements/elementsEnum';
import type { EditorValue } from '../../../types';
import type { DeepLinkFragment } from '~/graphql/types';

/**
 * Returns an array with all the deep links
 * @param {EditorValue} nodes - Slate value
 * keywords: get deepLinks from editor as DeepLinkFragment
 */
const getDeepLinksFromBody = (nodes: EditorValue): Array<DeepLinkFragment> => {
  const res: Array<DeepLinkFragment> = nodes.reduce((acc, node) => {
    if (Element.isElement(node) && node.type === ELEMENTS.DEEP_LINK) {
      const { mappingId, deeplinkType, deeplinkTypeId, mapping, deeplinkText } =
        node;

      if (!mappingId || !deeplinkType || !mapping) {
        return acc;
      }

      return [
        ...acc,
        {
          __typename: 'DeepLink',
          id: mappingId,
          link: {
            __typename: 'DeepLink_Link_StandaloneWidget',
            type: deeplinkType,
            typeId: deeplinkTypeId,
            mapping,
          },
          text: { template: deeplinkText },
        } as DeepLinkFragment,
      ];
    }

    /** Get deep links in nested elements */
    if (!Text.isText(node) && node.children) {
      const childNode = unnest(getDeepLinksFromBody(node.children));

      if (childNode.length > 0) return [...acc, ...childNode];
    }

    return acc;
  }, []);

  return res;
};

/** Make sure that there are no duplicate ones */
const getDeepLinks = value =>
  uniqBy(item => item.id, getDeepLinksFromBody(value));

export default getDeepLinks;
