import type {
  DeepLink__Input,
  DeepLink_Mapping__Input,
  DeepLinkFragment,
} from '~/graphql/types';

/**
 * Get the deep links value in the action and convert it into input values
 * @param {Array<DeepLinkFragment>} deepLinks - Deep links
 */
const getDeepLinksAsInputs = (
  deepLinks: Array<DeepLinkFragment>,
): Array<DeepLink__Input> =>
  deepLinks.map(deepLink => ({
    id: deepLink.id,
    text: { template: deepLink.text.template, mappings: [] },
    link: {
      DeepLink_Link_StandaloneWidget: {
        ...deepLink.link,
        mapping: deepLink.link.mapping.reduce((prev, item) => {
          if (item.value.__typename === 'Flow___Argument_Boolean') {
            prev.push({
              key: item.key,
              value: {
                value_boolean: item.value.value_boolean,
              },
            });
          }

          if (item.value.__typename === 'Flow___Argument_String') {
            prev.push({
              key: item.key,
              value: {
                value_string: item.value.value_string,
              },
            });
          }

          if (item.value.__typename === 'Flow___Argument_Integer') {
            prev.push({
              key: item.key,
              value: {
                value_integer: item.value.value_integer,
              },
            });
          }

          if (item.value.__typename === 'Flow___Argument_Pointer') {
            prev.push({
              key: item.key,
              value: {
                pointer: { path: item.value.pointer.path },
              },
            });
          }

          return prev;
        }, [] as Array<DeepLink_Mapping__Input>),
      },
    },
  }));

export default getDeepLinksAsInputs;
