import { useCallback } from 'react';
import { useNodes, useReactFlow } from 'reactflow';

import {
  DEFAULT_HEIGHT,
  DEFAULT_WIDTH,
} from '../../components/nodeTypes/constants';
import useViewportSize from '~/hooks/useViewportSize';

const LEFT_PADDING = 300;

/**
 * Pans/focuses a given action
 *
 * @returns a function to pan to the action
 */

const usePanToActionId = (actionId: string | null) => {
  const renderedNodes = useNodes();
  const currentNode = renderedNodes.find(({ id }) => actionId === id);

  const { width, height } = useViewportSize();
  const { fitBounds } = useReactFlow();

  const panTo = useCallback(() => {
    if (!currentNode) return;
    const { x, y } = currentNode.position;
    fitBounds(
      {
        x: x > 0 ? x - LEFT_PADDING - DEFAULT_WIDTH / 3 : -LEFT_PADDING,
        y: y - DEFAULT_HEIGHT / 2,
        width: width / 2 + LEFT_PADDING,
        height: height / 2,
      },
      { duration: 1000, padding: 0.6 },
    );
  }, [width, height, fitBounds, currentNode]);

  return panTo;
};

export default usePanToActionId;
