import React, { useCallback } from 'react';
import { NodeProps, NodeTypes } from 'reactflow';
import { useSetRecoilState } from 'recoil';

import { actionsSelector } from '~/components/page/Automation/v2/state';
import { getActionTemplate } from '../../constants/actionTemplates';

import AddNewNodeCard from '../AddNewNodeCard';
import TriggerCard from './components/TriggerCard';
import ZapierTriggerCard from './components/ZapierTriggerCard';
import CheckForCard from './components/IfElseCard';
import EmailActionCard from './components/EmailActionCard';
import { AddTagCard, DeleteTagCard } from './components/TagActionCard';
import WaitForCard from './components/WaitForCard';
import ContactDetailsCard from './components/ContactDetailsCard';
import SendRealworksContactActionCard from './components/SendRealworksContactActionCard';
import TaskActionCard from './components/TaskActionCard';
import AssignContactCard from './components/AssignContactCard';
import useBuilderContext from '../../hooks/useBuilderContext';
import type { ClientFlowAction } from '~/graphql/types.client';
import useErrorReporter from '~/hooks/useErrorReporter';
import parseEventData from '../edgeTypes/utils/parseEventData';
import TEST_ID from './index.testid';
import SourcePortalCard from './components/SourcePortalCard';
import TargetPortalCard from './components/TargetPortalCard';
import NotificationCard from './components/NotificationCard';

export type EmptyNodeData = {
  id: string;
  parentId: string | null;
  parentActionType?: ClientFlowAction['actionType'];
  childIdKey?: 'trueChildId' | 'falseChildId';
};
const EmptyCard: React.FCC<NodeProps<EmptyNodeData>> = React.memo(
  ({ data, xPos, yPos }) => {
    const reporter = useErrorReporter();
    const { accountId, flowBlueprintId } = useBuilderContext();

    const setActions = useSetRecoilState(
      actionsSelector({
        accountId,
        flowBlueprintId,
      }),
    );

    const onDrop = useCallback(
      (event: React.DragEvent<any>) => {
        const dropData = parseEventData(
          event.dataTransfer.getData('application/reactflow'),
          reporter,
        );

        // When dropData.id is equal to data.id we dropped it on it's own edge so do nothing
        if (dropData !== null && data != null && dropData.id !== data.id) {
          setActions(actions => {
            const mutableActions = [...actions];
            const parentActionIndex = mutableActions.findIndex(
              action => action.id === data.parentId,
            );
            const parentAction = mutableActions[parentActionIndex];
            const updatedParentAction = {
              ...parentAction,
            };

            if (data.childIdKey) {
              updatedParentAction[data.childIdKey] = dropData.id;
            }
            mutableActions[parentActionIndex] = updatedParentAction;

            let parentIds: Array<string> = [];

            // Most unlikely but when adding an additional entry point for the flow, parentId will be null
            if (data.parentId !== null) {
              parentIds = [data.parentId];
            }

            return [...mutableActions, { ...dropData, parentIds }];
          });
        }
      },
      [data, reporter, setActions],
    );

    const onAddAction = useCallback(
      (actionType: ClientFlowAction['actionType']) => {
        const actionTemplate = getActionTemplate({
          actionType,
          accountId,
          flowBlueprintId,
        });

        if (actionTemplate !== null) {
          setActions(actions => {
            const mutableActions = [...actions];
            const parentActionIndex = mutableActions.findIndex(
              action => action.id === data.parentId,
            );
            const parentAction = mutableActions[parentActionIndex];
            const updatedParentAction = {
              ...parentAction,
            };

            if (data.childIdKey) {
              updatedParentAction[data.childIdKey] = actionTemplate.id;
            }
            mutableActions[parentActionIndex] = updatedParentAction;

            let parentIds: Array<string> = [];

            // Most unlikely but when adding an additional entry point for the flow, parentId will be null
            if (data.parentId !== null) {
              parentIds = [data.parentId];
            }

            return [...mutableActions, { ...actionTemplate, parentIds }];
          });
        }
      },
      [accountId, flowBlueprintId, data.childIdKey, data.parentId, setActions],
    );

    return (
      <AddNewNodeCard
        dataTestId={TEST_ID.ADD_NODE}
        onDrop={onDrop}
        onAddAction={onAddAction}
        xPos={xPos ?? 0}
        yPos={yPos ?? 0}
        id={data.id}
        parentId={data.parentId}
        childIdKey={data.childIdKey}
        parentActionType={data.parentActionType}
      />
    );
  },
);

// The actual node types definition
// We match up typenames with card types we have
const nodeTypes: NodeTypes = {
  Start: TriggerCard,
  Wait: WaitForCard,
  IfElse: CheckForCard,
  Contact_AddTag: AddTagCard,
  Contact_DeleteTag: DeleteTagCard,
  SendEmail_Plain: EmailActionCard,
  SendNotification: NotificationCard,
  Zapier_Trigger: ZapierTriggerCard,
  Contact_Details: ContactDetailsCard,
  Contact_Assign: AssignContactCard,
  Task_Create: TaskActionCard,
  Realworks_SendContact: SendRealworksContactActionCard,

  // Util nodes
  empty: EmptyCard,
  source_portal: SourcePortalCard,
  target_portal: TargetPortalCard,
};

export default nodeTypes;
