import React, { useLayoutEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import Portal, { type RootId } from '~/components/molecule/Portal';
import calculateListPosition from './utils/calculateListPosition';

export type Props = {
  openerRect: DOMRect | null;
  listSize: ListSize;
  rootId: RootId;
};

export type ListSize = {
  width: number;
  height: number;
};

const PortalledPositioner: React.FCC<Props> = ({
  dataTestId,

  /**
   * Children to display in the portal
   */
  children,

  /**
   * DOMRect of the component that opened the list
   */
  openerRect,

  /**
   * Size of the list to open
   */
  listSize,

  /**
   * Portal root id
   */
  rootId,
}) => {
  const { newX, newY } = calculateListPosition({ openerRect, listSize });

  const [transform, setTransform] = useState<{ x: number; y: number }>({
    x: newX,
    y: newY,
  });

  useLayoutEffect(() => {
    const { newX, newY } = calculateListPosition({ openerRect, listSize });

    setTransform({ x: newX, y: newY });
  }, [openerRect, listSize]);

  return (
    <Portal root={rootId}>
      <PortalInner
        $top={transform.y}
        $left={transform.x}
        data-testid={dataTestId}
      >
        {children}
      </PortalInner>
    </Portal>
  );
};

const PortalInner = styled.div<{ $top: number; $left: number }>(
  ({ $top, $left, theme }) => css`
    position: absolute;
    top: ${$top || 0}px;
    left: ${$left || 0}px;
    /** TODO: Will be improved in https://app.shortcut.com/dathuis/story/13262/ensure-consistent-use-of-z-index-across-the-application
     Make sure it is higher than other portals like the hovering-toolbar portal
     */
    z-index: ${theme.z('top') + 2};
  `,
);

export default PortalledPositioner;
