import React, { useState } from 'react';
import { isNil, map, assoc, filter, propEq, omit } from 'ramda';
import styled, { css } from 'styled-components';

import { AccordionGroup } from '../..';
import ToggleCheckbox from '~/components/molecule/ToggleCheckbox';
import JustificationContainer from '~/components/atom/JustificationContainer';
import TextButton from '~/components/atom/TextButton';
import { useRecoilState } from 'recoil';
import Pin from '../Pin';
import pinnedAppsState, {
  PinnedApp,
  PinnedAppsMap,
} from '~/components/page/Widget/state/pinnedApps';
import GroupItems from '../GroupItems';
import { pinOptions } from '../../__fixtures__';
import alterById from '~/util/alterById';
import getPinnedItemsForGroup from '../../utils/getPinnedItemsForGroup';
import itemIsPinned from '../../utils/itemIsPinned';
import generatePinnedItem from '../../utils/generatePinnedItem';
import Loading from '~/components/atom/Loading';
import ToggleHiddenFlag from '../ToggleHiddenFlag';
import ShareIcon from '../ShareIcon';
import type { ShareAppArgs } from '~/components/page/Widget/components/Apps';
import CollapsibleBlock from '~/components/molecule/CollapsibleBlock';

export type Props = {
  /** Group id */
  id: string;

  /** Anchor id */
  anchorId: string;

  /** Group name */
  name: string;

  slug: string | null;

  /** Items to show in the nested block */
  items: Array<AccordionGroup> | null;

  /** checked state of the group */
  checked: boolean;

  /** Removes checkboxes to only display text */
  selectable: boolean;

  /** Hidden state of the group */
  hidden?: boolean;

  /** Changes groupLookup state */
  onChange: (args: {
    items?: Array<AccordionGroup> | null;
    mainChecked?: boolean;
    hidden?: boolean;
  }) => void;

  /** Opens a modal, where user can copy a deep link for an app with the provided slug  */
  onShare: (args: ShareAppArgs) => void;
};

export type PinAction = 'pin' | 'unpin';

const text = {
  selectAll: 'Alles deactiveren',
  deselectAll: 'Alles activeren',
  hideAll: 'Alles verbergen',
  unhideAll: 'Alles weergeven',
};

const Group: React.FCC<Props> = ({
  dataTestId,
  id,
  name,
  selectable,
  items,
  checked: mainChecked,
  onChange: onParentChange,
  hidden,
  onShare,
  slug,
  ...rest
}) => {
  const [contentOpen, setContentOpen] = useState<boolean>(!isNil(items));
  const [pinnedApps, setPinnedApps] = useRecoilState(pinnedAppsState);

  // Should not happen because these components will be loaded only if pinnedAppsState is not null (handled in Widget/index.tsx:109),
  // but for the type's sake
  if (pinnedApps === null) return <Loading />;

  const hasItems = !isNil(items);
  const checkedAmount = hasItems
    ? filter(propEq(true, 'checked'), items).length
    : 0;
  const isGroupChecked = hasItems ? checkedAmount === items.length : false;
  const partiallyChecked = hasItems
    ? !isGroupChecked && checkedAmount > 0
    : false;

  const allChecked = isGroupChecked || partiallyChecked;

  const onUnpin = (itemsToUnpin: Array<string>) => {
    const updatedItems = omit(itemsToUnpin, pinnedApps);
    setPinnedApps(updatedItems);
  };

  const onPin = (updatedItems: PinnedAppsMap, item: PinnedApp) =>
    setPinnedApps({
      ...updatedItems,
      [item.id]: item.pinned,
    });

  const onMainCheckedChange = () => {
    const update = !mainChecked;

    if (items && items.length > 0) {
      setContentOpen(true);

      if (update === false) {
        // If pinned items in a group and app is being turned off, unpin those items
        const pinnedAppsPerGroup = getPinnedItemsForGroup({
          items,
          pinnedApps,
        });
        onUnpin(pinnedAppsPerGroup);
      }
    }

    // When there are no items, unpin app if it is being turned off
    if (itemIsPinned(id, pinnedApps) && update === false) {
      onUnpin([id]);
    }

    onParentChange({ mainChecked: update });
  };

  const markAllCheckboxes = () => {
    if (!items) return;

    const selectAll = partiallyChecked ? true : !allChecked;

    if (selectAll === false) {
      const pinnedAppsPerGroup = getPinnedItemsForGroup({ items, pinnedApps });
      onUnpin(pinnedAppsPerGroup);
    }

    onParentChange({ items: map(assoc('checked', selectAll), items) });
  };

  const selectAll = !isGroupChecked || partiallyChecked;

  const toggleBlock = () => {
    if (hasItems) setContentOpen(prevOpen => !prevOpen);
  };

  const onItemChange = (
    id: string,
    field: 'checked' | 'hidden',
    value: boolean,
  ) => {
    if (!items) return;

    if (
      itemIsPinned(id, pinnedApps) &&
      field === 'checked' &&
      value === false
    ) {
      // Unpin item when app is being turned off
      onUnpin([id]);
    }

    const updated = alterById(id, field, value, items);
    const isGroupHidden = !updated.some(item => item.hidden === false);

    onParentChange({ items: updated, hidden: isGroupHidden });
  };

  const onPinChange = (type: PinAction, item: PinnedApp) => {
    if (type === 'unpin') {
      return onUnpin([item.id]);
    }

    const itemWithTheSameWeight = Object.keys(pinnedApps).find(
      key => pinnedApps[key].weight === item.pinned.weight,
    );
    const sameItemIsClicked = !isNil(pinnedApps[item.id]);

    if (sameItemIsClicked) {
      // Item with the same weight will be removed
      if (itemWithTheSameWeight) {
        const updatedItems = omit([itemWithTheSameWeight], pinnedApps);
        return onPin(updatedItems, item);
      }

      // Changes the weight of the same item
      if (pinnedApps[item.id].weight !== item.pinned.weight) {
        return onPin(pinnedApps, item);
      }
    }

    // Item will replace the previous item with this weight
    if (itemWithTheSameWeight) {
      const updatedItems = omit([itemWithTheSameWeight], pinnedApps);
      return onPin(updatedItems, item);
    }

    // Pin item
    return onPin(pinnedApps, item);
  };

  const pinnedItem = pinnedApps[id]
    ? { id, pinned: pinnedApps[id] }
    : undefined;

  const selectedOption = pinOptions.find(
    option => option.value === pinnedItem?.pinned.weight,
  );

  const toggleAllHiddenProp = () => {
    if (!mainChecked) return;

    const hiddenItems = items?.map(item => ({ ...item, hidden: !hidden }));
    onParentChange({
      items: hiddenItems,
      mainChecked,
      hidden: !hidden,
    });
  };

  return (
    <CollapsibleBlock
      data-testid={dataTestId}
      id={id}
      header={
        <HeaderContainer
          align="center"
          justification="space-between"
          data-objectid={id}
        >
          <Label
            gap={!hasItems ? 'base' : undefined}
            margin={hasItems ? [null, 'base'] : [null]}
            onClick={toggleBlock}
            $clickable={hasItems}
          >
            {!hasItems && (
              <>
                <Pin
                  disabled={!mainChecked}
                  selectedOption={selectedOption}
                  options={pinOptions}
                  onClick={(type, weight) => {
                    const itemToPin = generatePinnedItem({ id, weight });
                    onPinChange(type, itemToPin);
                  }}
                />
                <ToggleHiddenFlag
                  hidden={hidden ?? false}
                  onClick={toggleAllHiddenProp}
                  disabled={!mainChecked}
                />
                <ShareIcon
                  disabled={!mainChecked}
                  onClick={() => onShare({ slug, name })}
                />
              </>
            )}
            {name}
          </Label>

          <JustificationContainer align="center" gap="base">
            {contentOpen && mainChecked && (
              <JustificationContainer align="center" gap="s">
                <TextButton
                  padding={[null]}
                  onClick={markAllCheckboxes}
                  icon={selectAll ? 'check-square' : 'square'}
                  label={selectAll ? text.selectAll : text.deselectAll}
                />
                <TextButton
                  label={hidden ? text.unhideAll : text.hideAll}
                  icon={hidden ? 'eye-closed' : 'eye'}
                  onClick={toggleAllHiddenProp}
                />
              </JustificationContainer>
            )}

            <ToggleCheckbox
              value={mainChecked}
              onChange={onMainCheckedChange}
              size="small"
            />
          </JustificationContainer>
        </HeaderContainer>
      }
      itemsLength={items?.length ?? 0}
      variant="tertiary"
      isOpen={contentOpen}
      onToggle={toggleBlock}
      disabled={!mainChecked}
      {...rest}
    >
      {items && (
        <GroupItems
          onShare={onShare}
          pinnedApps={pinnedApps}
          onItemChange={onItemChange}
          selectable={selectable}
          checked={mainChecked}
          onPin={onPinChange}
          items={items}
        />
      )}
    </CollapsibleBlock>
  );
};

const Label = styled(JustificationContainer)<{ $clickable: boolean }>(
  ({ theme, $clickable }) => css`
    font-weight: ${theme.fw('semiBold')};
    cursor: ${$clickable ? 'pointer' : 'auto'};
  `,
);

const HeaderContainer = styled(JustificationContainer)<{}>(
  ({ theme }) => css`
    font-weight: ${theme.fw('semiBold')};
    width: 100%;
  `,
);

export default Group;
