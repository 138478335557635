import type { IconType } from '~/components/atom/Icon';
import type {
  FormBuilder_Locale,
  FormBuilder_ScreenNode_Block,
} from '~/graphql/types';
import type { OptionOf } from '~/components/molecule/Dropdown';
import * as TypographyComps from '~/components/atom/Typography';
import { Props as TypographyProps } from '~/components/atom/Typography';
import type { TypographyComponentType } from '../components/ScreenPreview/utils/formatNode/types';
import type { ColorString } from '~/theme/System/types';

export const ItemTypes = {
  BLOCK_COMPONENT: 'BLOCK_COMPONENT',
};
export const SUBMIT_SCREEN_TITLE = 'Verzendpagina';

export const AREA_HEIGHT = '650px';
export enum DND_SOURCES {
  componentsCollection = 'componentsCollection',
  blocksArea = 'blocksArea',
}

export const localeToIntlKey: Record<FormBuilder_Locale, string> = {
  NL: 'nl',
  EN: 'en',
};

export const localeToLabel: Record<FormBuilder_Locale, string> = {
  NL: 'Label',
  EN: 'Label',
};

export type ScreenNodeBlock_FE =
  | FormBuilder_ScreenNode_Block['__typename']
  | 'FormData_GDPR';

export type BlocksMap = {
  [key in ScreenNodeBlock_FE]: {
    icon: IconType;
    label: { nl: string; en: string };
    color: ColorString;
    placeHolder?: string;
  };
};

export const blocksMap: BlocksMap = {
  FormData_Input_Email: {
    label: { nl: 'E-mailadres', en: 'Email address' },
    icon: 'email-sign',
    color: '#1E2647',
    placeHolder: 'example@email.com',
  },
  FormData_Input_Integer: {
    label: { nl: 'Getal', en: 'Number' },
    icon: 'number',
    color: '#FF6900',
    placeHolder: '13567894342',
  },
  FormData_Input_Multiline: {
    label: { nl: 'Meerdere regels tekst', en: 'Multiple line text' },
    icon: 'multi-choice',
    color: '#3763D4',
    placeHolder:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
  },
  FormData_Input_Text: {
    label: { nl: 'Enkele regel tekst', en: 'Single line text' },
    icon: 'text-background',
    color: '#FFB464',
    placeHolder: 'Placeholder text',
  },
  FormData_Input_Address: {
    label: { nl: 'Adres', en: 'Address' },
    icon: 'home',
    color: '#9C27B0',
    placeHolder: 'Hoofdstraat 1, 1234 AB Amsterdam',
  },
  FormData_Select_Dropdown: {
    label: { nl: 'Keuzemenu', en: 'Dropdown' },
    icon: 'dropdown',
    color: '#018827',
  },
  FormData_Select_MultiButton: {
    label: { nl: 'Multiple-choice', en: 'Multiple-choice' },
    icon: 'multi-choice',
    color: '#FFD6A8',
  },
  FormData_Select_Radio: {
    label: { nl: 'Meerkeuze vraag', en: 'Multiple-choice question' },
    icon: 'single-choice',
    color: '#C6020B',
  },
  FormData_UI_Image: {
    label: { nl: 'Afbeelding', en: 'Image' },
    icon: 'image',
    color: '#B6D3FF',
  },
  FormData_UI_RichText: {
    label: { nl: 'Opgemaakte paragraaf', en: 'Rich text' },
    icon: 'rich-text',
    color: '#213C80',
  },
  FormData_UI_Typography: {
    label: { nl: 'Koptekst', en: 'Heading' },
    icon: 'typography',
    color: '#642901',
  },
  FormData_Input_Contact_Address: {
    icon: 'home',
    label: { nl: 'Woonadres', en: 'Home address' },
    color: '#9C27B0',
    placeHolder: 'Hoofdstraat 1, 1234 AB Amsterdam',
  },
  FormData_Input_Contact_Email: {
    icon: 'mail',
    label: { nl: 'E-mailadres', en: 'Email address' },
    color: '#FFB464',
    placeHolder: 'example@email.com',
  },
  FormData_Input_Contact_Name: {
    icon: 'typography',
    label: { nl: 'Naam', en: 'Name' },
    color: '#A2040E',
    placeHolder: 'John Johnson',
  },
  FormData_Input_Contact_Phone: {
    icon: 'phone',
    // The `-` in `telefoon-nummer` is added to break `nummer` word to the next line
    label: { nl: 'Telefoonnummer', en: 'Phone number' },
    color: '#018827',
    placeHolder: '3197010587401',
  },
  FormData_GDPR: {
    icon: 'check-square',
    label: { nl: 'AVG', en: 'GDPR' },
    color: '#213C80',
  },
};

export const BLOCK_OPTIONS: Array<OptionOf<ScreenNodeBlock_FE>> = [
  {
    payload: 'FormData_Input_Contact_Name',
    key: 'FormData_Input_Contact_Name',
    label: blocksMap['FormData_Input_Contact_Name'].label.nl,
  },
  {
    payload: 'FormData_Input_Contact_Email',
    key: 'FormData_Input_Contact_Email',
    label: blocksMap['FormData_Input_Contact_Email'].label.nl,
  },
  {
    payload: 'FormData_Input_Contact_Phone',
    key: 'FormData_Input_Contact_Phone',
    label: blocksMap['FormData_Input_Contact_Phone'].label.nl,
  },
  {
    payload: 'FormData_Input_Contact_Address',
    key: 'FormData_Input_Contact_Address',
    label: blocksMap['FormData_Input_Contact_Address'].label.nl,
  },
  {
    payload: 'FormData_Input_Text',
    key: 'FormData_Input_Text',
    label: blocksMap['FormData_Input_Text'].label.nl,
  },
  {
    payload: 'FormData_Input_Address',
    key: 'FormData_Input_Address',
    label: blocksMap['FormData_Input_Address'].label.nl,
  },
  {
    payload: 'FormData_Input_Multiline',
    key: 'FormData_Input_Multiline',
    label: blocksMap['FormData_Input_Multiline'].label.nl,
  },
  {
    payload: 'FormData_Input_Integer',
    key: 'FormData_Input_Integer',
    label: blocksMap['FormData_Input_Integer'].label.nl,
  },
  {
    payload: 'FormData_Select_Dropdown',
    key: 'FormData_Select_Dropdown',
    label: blocksMap['FormData_Select_Dropdown'].label.nl,
  },
  {
    payload: 'FormData_Select_Radio',
    key: 'FormData_Select_Radio',
    label: blocksMap['FormData_Select_Radio'].label.nl,
  },
  {
    payload: 'FormData_UI_RichText',
    key: 'FormData_UI_RichText',
    label: blocksMap['FormData_UI_RichText'].label.nl,
  },
  {
    payload: 'FormData_UI_Image',
    key: 'FormData_UI_Image',
    label: blocksMap['FormData_UI_Image'].label.nl,
  },
  {
    payload: 'FormData_GDPR',
    key: 'FormData_Select_Radio',
    label: blocksMap['FormData_GDPR'].label.nl,
  },
];

export const typographyMap: Record<
  TypographyComponentType['props']['type'],
  React.FCC<TypographyProps>
> = {
  Heading1: TypographyComps.Heading1,
  Heading2: TypographyComps.Heading2,
  Heading3: TypographyComps.Heading3,
  Heading4: TypographyComps.Heading4,
  Heading5: TypographyComps.Heading5,
  Heading6: TypographyComps.Heading6,
  SubHeading: TypographyComps.SubHeading,
  Body: TypographyComps.Body,
};
