import React, { useEffect } from 'react';
import {
  RenderElementProps,
  useFocused,
  useSelected,
  useSlate,
} from 'slate-react';
import ELEMENTS from '~/components/organism/PluginsEditor/components/elements/elementsEnum';
import type { DeepLinkElement } from '../../../types';
import serialize from './serialize';
import { MISSING_DEEP_LINK_CONFIG_ID } from '../../../constants';
import styled, { css } from 'styled-components';
import type { BaseColor } from '~/theme/System/tokens';
import useTooltipLayer from '~/hooks/useTooltipLayer';
import EditableBlockWithIcon from '../components/EditableBlockWithIcon';
import useDragProps from '../../../hooks/useDragProps';

/**
 * It would be a heavy operation to get this colour from the browser so we make it a constant
 */
const SELECTED_TEXT_BACKGROUND_COLOR = 'rgb(173, 210, 252)';

const text = {
  notAvailable: 'Deze app link bestaat niet meer',
};

export type Props = RenderElementProps & {
  element: DeepLinkElement;
};

export const DeepLink: React.FC<Props> = ({
  attributes,
  element,
  children,
}) => {
  const editor = useSlate();
  const selected = useSelected();
  const focused = useFocused();
  const active = selected && focused;

  const configMissing =
    element.deeplinkConfigName === MISSING_DEEP_LINK_CONFIG_ID;

  const color = configMissing ? 'danger' : 'secondary';
  const deeplinkConfigLabel = configMissing
    ? text.notAvailable
    : element.deeplinkConfigName;

  const tooltipProps = useTooltipLayer({
    tooltipMessage: `Link naar '${deeplinkConfigLabel}'`,
  });

  const dismissTooltip = () => tooltipProps.onMouseLeave();
  const { onDragStart, ...dragProps } = useDragProps(editor);

  useEffect(() => {
    if (active) dismissTooltip();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active]);

  return (
    <Container
      {...attributes}
      contentEditable={false}
      onClick={dismissTooltip}
      {...dragProps}
      onDragStart={() => {
        onDragStart();
        dismissTooltip();
      }}
      {...(active ? {} : tooltipProps)}
    >
      {children}

      {configMissing ? (
        <EditableBlockWithIcon iconName="alert-circle" active={active} hasError>
          {element.deeplinkText}
        </EditableBlockWithIcon>
      ) : (
        <DeepLinkTextContainer
          $active={active}
          $color={color}
          $hasError={configMissing}
        >
          {element.deeplinkText}
        </DeepLinkTextContainer>
      )}
    </Container>
  );
};
const Container = styled.span<{}>`
  user-select: text;
`;

const DeepLinkTextContainer = styled.span<{
  $active: boolean;
  $color: BaseColor;
  $hasError: boolean;
}>(
  ({ theme, $active, $color, $hasError }) => css`
    display: inline-flex;
    align-items: center;
    color: ${theme.color($color, 'dark')};
    text-decoration: underline;

    ${$active &&
    css`
      font-weight: ${theme.fontWeight('semiBold')};
      color: ${theme.color($color)};
    `}

    &:hover {
      color: ${theme.color($color)};
    }

    &::selection {
      background-color: ${SELECTED_TEXT_BACKGROUND_COLOR};
    }

    ${$hasError &&
    css`
      background-color: ${theme.color('danger', 'translucent')};
      padding: ${theme.space('xxxs')} ${theme.space('xxs')};
      border-radius: ${theme.getTokens().border.radius.s};
    `}

    cursor: pointer;
  `,
);

export default {
  nodeName: 'DEEPLINK',
  renderComponent: props => <DeepLink {...props} />,
  deserialize: el => ({
    type: ELEMENTS.DEEP_LINK,
    mappingId: el.getAttribute('mappingId'),
    deeplinkType: el.getAttribute('deeplinkType'),
    deeplinkTypeId: el.getAttribute('deeplinkTypeId'),
    deeplinkText: el.getAttribute('deeplinkText'),
    deeplinkConfigName: el.getAttribute('deeplinkConfigName'),
    mapping: JSON.parse(el.getAttribute('mapping').replace(/'/g, '"')),
  }),
  serialize,
};
