import type { ClientFlowActionSendEmailPlain } from '~/graphql/types.client';
import generateIdForEntity from '~/util/generateIdForEntity';

/**
 * Regenerates and replaces deep link ids for the deep links that are inserted in the body
 * Update is made in-place
 *
 * @param {ClientFlowActionSendEmailPlain} mutableNewAction - Action to update the body template and deepLinks
 * keywords:regenerateId, newId, deepLinkId
 */
const updateDeepLinkIds = ({
  mutableNewAction,
}: {
  mutableNewAction: ClientFlowActionSendEmailPlain;
}) => {
  const idMap: { [oldId: string]: string } = {};

  // update IDs in deepLinks array
  const updatedDeepLinks = mutableNewAction.deepLinks.map(item => {
    const newId = generateIdForEntity('DEEP_LINK');
    idMap[item.id] = newId;
    return {
      ...item,
      id: newId,
    };
  });

  mutableNewAction.deepLinks = updatedDeepLinks;

  // Update the body template with new IDs
  if (mutableNewAction.body?.template) {
    let updatedTemplate = mutableNewAction.body.template;
    Object.entries(idMap).forEach(([oldId, newId]) => {
      updatedTemplate = updatedTemplate.replaceAll(
        `_%${oldId}%_`,
        `_%${newId}%_`,
      );
    });

    mutableNewAction.body.template = updatedTemplate;
  }
};
export default updateDeepLinkIds;
