import type {
  Flow___ArgumentFragment,
  Flow___SubjectId,
} from '~/graphql/types';
import type { RelativeMaps } from '~/components/page/Automation/v2/types';
import type { Flow___SubjectIdentifier } from '../getSubject';
import { assertNever } from '~/util/assertNever';
import getPathForPointer from '../getPathForPointer';

/**
 * Returns the output subject id for a given argument.
 */
const getSubjectIdForArgument = (
  argument: Flow___ArgumentFragment,
  maps: RelativeMaps,
): { error: string } | { error?: undefined; subjectId: Flow___SubjectId } => {
  if (argument.__typename === 'Flow___Argument_Pointer') {
    const res = getPathForPointer(argument.pointer, maps);
    if (res.error != null) return { error: res.error };

    return generateResult(res.resultingSubjectId);
  }

  switch (argument.__typename) {
    case 'Flow___Argument_Boolean':
      return generateResult({
        type: 'FlowSubject_Primitive_Boolean',
      });
    case 'Flow___Argument_Integer':
      return generateResult({
        type: 'FlowSubject_Primitive_Integer',
      });
    case 'Flow___Argument_String':
      return generateResult({
        type: 'FlowSubject_Primitive_String',
      });
    case 'Flow___Argument_File':
      return generateResult({
        type: 'FlowSubject_Primitive_File',
      });
    case 'Flow___Argument_Float':
    case 'Flow___Argument_AWSDateTime':
      throw Error(`Unimplemented`);

    default:
      return assertNever(argument);
  }
};

const generateResult = (
  arg: Flow___SubjectIdentifier,
): { subjectId: Flow___SubjectId } => ({
  subjectId: {
    __typename: 'Flow___SubjectId',
    ...arg,
  },
});

export default getSubjectIdForArgument;
