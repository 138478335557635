import React from 'react';
import styled, { css } from 'styled-components';
import Icon, { type IconType } from '~/components/atom/Icon';
import JustificationContainer from '~/components/atom/JustificationContainer';
import type { SystemColorPalette } from '~/theme';

export type Props = {
  dataTestId?: string;
  className?: string;

  /** If element is active and focused */
  active: boolean;

  /** Error state of the container */
  hasError: boolean;

  /** Icon to display on the left */
  iconName: IconType;

  /** Determines the color of the icon and the underline */
  color?: keyof SystemColorPalette;
};

const EditableBlockWithIcon: React.FCC<Props> = ({
  dataTestId,
  className,
  children,
  active,
  hasError,
  iconName,
  color,
}) => (
  <Container
    contentEditable={false}
    data-testid={dataTestId}
    className={className}
    $active={active}
    $hasError={hasError}
    $color={color || 'primary'}
  >
    <JustificationContainer gap="xxxs" align="center">
      <StyledIcon name={iconName} strokeWidth={active ? 3 : 2.5} />
      {children}
    </JustificationContainer>
  </Container>
);

const StyledIcon = styled(Icon)(
  ({ theme }) => css`
    & > svg {
      height: 0.8em;
      width: 0.8em;
      margin-right: calc(${theme.space('xxs')} / 2);
    }
  `,
);

type ContainerProps = {
  $active: boolean;
  $hasError: boolean;
  $color: keyof SystemColorPalette;
};

const Container = styled.span<ContainerProps>(
  ({ $active, $hasError, $color, theme }) => css`
    padding: ${theme.space('xxxs')} ${theme.space('xxs')};
    font-size: ${theme.fontSize('base')};
    cursor: pointer;
    display: inline-block;
    line-height: ${theme.lineHeight('base')};
    font-weight: ${theme.fontWeight('regular')};
    /** Leave some space around it with the element in the next line and the cursor left and right */
    margin: 0px 1px 1px 1px;

    color: ${theme.color($color, 'light')};
    border: 1px dashed ${theme.color('white', 'dark')};
    border-radius: ${theme.getTokens().border.radius.s};
    background-color: ${$hasError
      ? theme.color('danger', 'translucent')
      : theme.color($color, 'translucent')};

    ${$active &&
    css`
      font-weight: ${theme.fontWeight('medium')};
    `}

    &:hover {
      font-weight: ${theme.fontWeight('medium')};
      background-color: ${$hasError
        ? theme.color('danger', 'translucent')
        : theme.color($color, 'translucent')};

      & svg {
        stroke-width: 3;
      }
    }

    ${$hasError &&
    css`
      color: ${theme.color('danger')};
      border: 1px dashed ${theme.color('danger', 'translucent')};
    `}
  `,
);

export default EditableBlockWithIcon;
