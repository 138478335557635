import React, { useRef } from 'react';
import styled, { css } from 'styled-components';

import useOutsideClick from '~/hooks/useClickOutside';
import isCursorInBoundary from '~/util/isCursorInBoundary';
import { isNil } from 'ramda';
import PortalledPositioner from '../PortalledPositioner';
import type { RootId } from '../Portal';

export type Props = {
  isVisible: boolean;
  onClickOutside?: (e: MouseEvent) => void;
  openerRef: HTMLElement | null;
  rootId: RootId;
};

const MAX_WIDTH_FOR_POPUP = 400;
const MAX_HEIGHT_FOR_POPUP = 400;

const ResponsivePopup: React.FCC<Props> = ({
  dataTestId,
  onClickOutside,
  isVisible,
  openerRef,
  rootId,
  children,
}) => {
  const openerRect = openerRef?.getBoundingClientRect();
  const childrenRef = useRef(null);

  useOutsideClick(childrenRef, e => {
    if (isNil(e) || isNil(openerRect) || isNil(onClickOutside)) return;

    const isClickOnOpener = isCursorInBoundary({
      event: e,
      boundary: openerRect,
    });
    if (isClickOnOpener) return;
    onClickOutside(e);
  });

  if (isVisible) {
    return (
      <PortalledPositioner
        rootId={rootId}
        openerRect={openerRect || null}
        listSize={{ width: MAX_WIDTH_FOR_POPUP, height: MAX_HEIGHT_FOR_POPUP }}
        dataTestId={dataTestId}
      >
        <div ref={childrenRef}>{children}</div>
      </PortalledPositioner>
    );
  }

  return null;
};

// Wrap the children with this component if you want to style the element
export const BoxShadowContainer = styled.div<{ $width?: string }>`
  ${({ theme, $width }) => css`
    width: ${$width || '200px'};
    border-radius: ${theme.getTokens().border.radius.base};
    background-color: ${theme.color('white')};
    padding: ${theme.space('base')};
    box-shadow: ${theme.boxShadow('base')};
  `}
`;

export default ResponsivePopup;
