export const tooltipText = {
  /** Inline */
  bold: 'Vet',
  italic: 'Cursief',
  underline: 'Onderstreept',
  color: 'Tekstkleur',
  backgroundColor: 'Achtergrondkleur',
  fontSize: 'Lettergrootte',
  fontFamily: 'Lettertype',

  /** Block */
  alignment: 'Uitlijnen',
  'numbered-list': 'Geordende lijst',
  'bulleted-list': 'Ongeordende lijst',
  indent: 'Inspringen vergroten',
  outdent: 'Inspringen verkleinen',
  'heading-one': 'Heading 1',
  'heading-two': 'Heading 2',
  'heading-three': 'Heading 3',
  'heading-four': 'Heading 4',
  'heading-five': 'Heading 5',
  'heading-six': 'Heading 6',
  'block-quote': 'Citaat',
  typography: 'Typografie invoegen',

  /** Custom */
  insertImage: 'Afbeelding invoegen',
  insertLink: 'Link invoegen',
  insertHtml: 'HTML invoegen',
  debugHtml: 'HTML wijzigen',
  insertVariable: 'Variabele invoegen',
  insertDeepLink: 'App link invoegen',
  undo: 'Ongedaan maken',
  redo: 'Opnieuw',
  fullscreen: 'Volledig scherm',
  insertImmutableHtml: 'Statische HTML invoegen',
};
