import type {
  FormBuilder_ScreenNodeFragment,
  FormData_Input_Address,
  FormData_Input_Contact_Address,
  FormData_Input_Contact_Email,
  FormData_Input_Contact_Name,
  FormData_Input_Contact_Phone,
  FormData_Input_Email,
  FormData_Input_Integer,
  FormData_Input_Multiline,
  FormData_Input_Text,
} from '~/graphql/types';

export type InputBlockType =
  | FormData_Input_Email
  | FormData_Input_Integer
  | FormData_Input_Multiline
  | FormData_Input_Text
  | FormData_Input_Address
  | FormData_Input_Contact_Name
  | FormData_Input_Contact_Address
  | FormData_Input_Contact_Email
  | FormData_Input_Contact_Phone;

const isInputTypeBlock = (
  block: FormBuilder_ScreenNodeFragment['blocks'][number],
): block is InputBlockType => {
  switch (block.__typename) {
    case 'FormData_Input_Email':
    case 'FormData_Input_Integer':
    case 'FormData_Input_Multiline':
    case 'FormData_Input_Text':
    case 'FormData_Input_Address':
    case 'FormData_Input_Contact_Address':
    case 'FormData_Input_Contact_Name':
    case 'FormData_Input_Contact_Email':
    case 'FormData_Input_Contact_Phone': {
      return true;
    }

    case 'FormData_Select_Dropdown':
    case 'FormData_Select_MultiButton':
    case 'FormData_Select_Radio':
    default: {
      return false;
    }
  }
};

export default isInputTypeBlock;
