import React from 'react';
import styled, { css } from 'styled-components';
import CloseButton from '~/components/atom/CloseButton';
import JustificationContainer from '~/components/atom/JustificationContainer';

export type Props = {
  onClose?: () => void;
  maxWidth?: string;
  onAnimationRest?: () => void;
};

const DEFAULT_MAX_WIDTH = '1100px';

const Modal: React.FCC<Props> = ({
  dataTestId,
  onClose,
  children,
  maxWidth = DEFAULT_MAX_WIDTH,
}) => (
  <Container
    data-testid={dataTestId}
    $maxWidth={maxWidth}
    onClick={e => e.stopPropagation()}
  >
    {onClose && <CloseButton onClick={onClose} data-testid="close-button" />}
    <Inner id="modal-inner-overflow-container">{children}</Inner>
  </Container>
);

const Inner = styled.div<{}>(
  ({ theme }) => css`
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 90vh;
    border-radius: ${theme.getTokens().border.radius.m};
  `,
);

const Container = styled(JustificationContainer)<{ $maxWidth: string }>(
  ({ theme, $maxWidth }) => css`
    position: relative;
    background: ${theme.color('white')};
    max-width: ${$maxWidth};
    top: 50%;
    transform: translateY(-50%);
    margin: auto;
    border-radius: ${theme.getTokens().border.radius.m};
    box-shadow: ${theme.getTokens().boxShadow.modal};

    ${theme.mq.lessThan('desktop')`
      width: ${$maxWidth};
      max-width: 90vw;
    `}
  `,
);

export default Modal;
