import { isEmpty, slice, take } from 'ramda';
import React from 'react';
import styled, { css } from 'styled-components';
import { messages } from '~/components/page/Automation/v2/components/Builder/getIssuesForConditionExpression';
import { FlowAction } from '~/graphql/types';
import { getConnectorOperatorLabel } from '~/components/page/Automation/v2/components/UpdateAction/components/ConditionEditorV2/components/ConditionGroup/components/ConnectorComponent';
import extendRelativeMaps from '../../../../utils/getUpToDateRelativeMaps/utils/extendRelativeMaps';
import JustificationContainer from '~/components/atom/JustificationContainer';
import Condition from './components/Condition';
import ConditionGroups from './components/ConditionGroups';
import type { ConditionExpression } from '~/components/page/Automation/v2/components/UpdateAction/components/ConditionEditorV2/types';
import type { RelativeMaps } from '~/components/page/Automation/v2/types';

export type Props = {
  dataTestId?: string;
  actionId: string;
  actionType: FlowAction;
  condition: ConditionExpression;
  maps: RelativeMaps;
};

const NUMBER_OF_VISIBLE_TRIGGERS = 1;

const ConditionList: React.FCC<Props> = ({
  dataTestId,
  actionId,
  condition,
  actionType,
  maps,
}) => {
  const hasTriggers = ((): boolean => {
    switch (actionType) {
      case FlowAction.Start:
      case FlowAction.Wait:
        return true;

      default:
        return false;
    }
  })();

  const visibleTriggers = take(
    NUMBER_OF_VISIBLE_TRIGGERS,
    condition.conditionSubExpression,
  );
  const collapsedTriggers = slice(
    NUMBER_OF_VISIBLE_TRIGGERS,
    condition.conditionSubExpression.length,
    condition.conditionSubExpression,
  );

  return (
    <JustificationContainer
      data-testid={dataTestId}
      direction="column"
      gap="xxs"
      width="100%"
    >
      {visibleTriggers.map((subExpression, subExpressionIdx) => {
        const subExpressionConnector = getConnectorOperatorLabel(
          subExpression.connector,
        );

        /**
         * The first condition technically does not need to be a trigger, depending on
         * whether the action supports them they are.
         *
         * @see hasTrigger
         */
        const [trigger, ...conditionGroups_] = subExpression.conditionGroups;
        const conditionMaps = (() => {
          if (!hasTriggers) return maps;

          return extendRelativeMaps({
            actionId,
            condition: trigger.conditions[0],
            maps,
          });
        })();

        const [conditionGroups, moreConditionGroups] = (() => {
          const SHOW_CONDITION_GROUPS = 1;
          const conditions = conditionGroups_.slice(0, SHOW_CONDITION_GROUPS);
          const moreConditionGroups =
            conditionGroups_.length > SHOW_CONDITION_GROUPS
              ? conditionGroups_.length - SHOW_CONDITION_GROUPS
              : 0;

          return [conditions, moreConditionGroups];
        })();

        return (
          <SubConditionContainer key={subExpressionIdx}>
            <TriggerContainer padding={['s']} wrap="wrap" gap="xxs">
              {trigger.conditions.map((condition, conditionIdx) => {
                const key = `${subExpressionIdx}-${conditionIdx}`;

                if (!condition)
                  return <p key={key}>{messages.emptyCondition}</p>;

                const connector = getConnectorOperatorLabel(trigger.connector);
                const isLast = conditionIdx === trigger.conditions.length - 1;
                const layout =
                  conditionIdx === 0 ? 'complete' : 'onlyArguments';

                const type = (() => {
                  if (hasTriggers) {
                    return conditionIdx === 0 ? 'trigger' : 'condition';
                  }

                  return 'condition';
                })();

                return (
                  <React.Fragment key={key}>
                    <Condition
                      actionType={actionType}
                      condition={condition}
                      layout={layout}
                      maps={maps}
                      type={type}
                      isLast={isLast}
                    />
                    {!isLast && connector}
                  </React.Fragment>
                );
              })}
            </TriggerContainer>
            {conditionGroups.length !== 0 && (
              <ConditionGroups
                conditionGroups={conditionGroups}
                subExpressionIdx={subExpressionIdx}
                moreConditionGroups={moreConditionGroups}
                conditionMaps={conditionMaps}
                actionType={actionType}
                subExpressionConnector={subExpressionConnector}
              />
            )}
          </SubConditionContainer>
        );
      })}
      {!isEmpty(collapsedTriggers) && (
        <FieldContainer>{`+ ${collapsedTriggers.length} meer`}</FieldContainer>
      )}
    </JustificationContainer>
  );
};

const FieldContainer = styled.div<{ withIssue?: boolean }>(
  ({ theme, withIssue = false }) => css`
    border-radius: ${theme.getTokens().border.radius.base};
    padding: ${theme.space('xxs')};
    margin-top: ${theme.space('base')};
    border: ${theme.getTokens().border.width.s} solid
      ${withIssue ? theme.color('danger') : theme.color('tertiary', 'light')};
  `,
);

const SubConditionContainer = styled.div(
  ({ theme }) => css`
    width: 100%;
    border: 1px solid ${theme.color('tertiary', 'light')};
    border-radius: ${theme.getTokens().border.radius.base};
    line-height: ${theme.lineHeight('m')};
  `,
);

const TriggerContainer = styled(JustificationContainer)<{}>(() => css``);

export default ConditionList;
