import React, { HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import Icon from '~/components/atom/Icon';

export type Props = {} & HTMLAttributes<HTMLButtonElement>;

const CloseButton: React.FCC<Props> = ({ dataTestId, onClick, ...rest }) => (
  <Container data-testid={dataTestId} onClick={onClick} type="button" {...rest}>
    <Icon name="close" strokeWidth={2.5} />
  </Container>
);

const Container = styled.button<{}>(
  ({ theme }) => css`
    position: absolute;
    right: -${theme.space('m')};
    top: -${theme.space('m')};
    width: ${theme.space('xl')};
    height: ${theme.space('xl')};

    box-shadow: ${theme.getTokens().boxShadow.base};
    border-radius: ${theme.getTokens().border.radius.full};
    border: none;
    color: ${theme.color('primary', 'light')};
    background-color: ${theme.color('white')};
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease-out;
    z-index: ${theme.getTokens().zIndex.top};
    cursor: pointer;

    &:hover {
      box-shadow: ${theme.getTokens().boxShadow.cardHover};
      background-color: ${theme.color('tertiary', 'light')};
      color: ${theme.color('danger')};
    }
  `,
);

export default CloseButton;
