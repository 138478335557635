import React, { useState } from 'react';
import styled, { css, useTheme } from 'styled-components';
import { IbanElement } from '@stripe/react-stripe-js';
import { Theme } from '~/theme';
import TEST_ID from './index.testid';
import InputLabel from '../InputLabel';

const text = {
  ibanLabel: 'IBAN',
  emptyField: 'Het opgegeven IBAN is onvolledig',
};
type Props = {
  elements: any;
  currentLast4?: string | null;
  onChange?: () => void;
};
const IBANElement: React.FCC<Props> = ({ elements, onChange }) => {
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const theme: Theme = useTheme();

  return (
    <Container>
      <InputLabel
        required
        label={text.ibanLabel}
        error={errorMsg}
        dataTestId={TEST_ID.IBAN_FIELD_LABEL}
      />
      <IbanFieldContainer data-testid={TEST_ID.IBAN_FIELD}>
        <IbanElement
          id="iban-element"
          options={IBAN_ELEMENT_OPTIONS(theme)}
          onChange={event => {
            if (event?.error != null) {
              setErrorMsg(event.error.message);
            } else {
              setErrorMsg(event.empty ? text.emptyField : null);
            }

            if (onChange) onChange();
          }}
          onBlur={() => {
            const ibanElement = elements.getElement(IbanElement);

            if (ibanElement._empty) {
              setErrorMsg(text.emptyField);
            }
          }}
        />
      </IbanFieldContainer>
    </Container>
  );
};

const IBAN_ELEMENT_OPTIONS = (theme: Theme) => ({
  supportedCountries: ['SEPA'],
  style: {
    base: {
      color: theme.color('text'),
      fontFamily: 'inherit',
      fontSmoothing: 'antialiased',
      fontSize: theme.remToPx(theme.fontSize('base')),
      ':-webkit-autofill': {
        color: theme.color('text'),
      },
    },
    invalid: {
      color: theme.color('danger'),
      iconColor: theme.color('danger'),
      ':-webkit-autofill': {
        color: theme.color('danger'),
      },
    },
  },
  placeholderCountry: 'NL',
});

const Container = styled.div<{}>`
  width: 100%;
`;

const IbanFieldContainer = styled.div<{}>(
  ({ theme }) => css`
    border: ${theme.getTokens().border.width.s} solid ${theme.color('tertiary')};
    border-radius: ${theme.getTokens().border.radius.base};
    padding: ${theme.space('xs')};
  `,
);

export default IBANElement;
