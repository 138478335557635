import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { Form, Field } from 'react-final-form';
import { isNil } from 'ramda';
import { IbanElement, useStripe, useElements } from '@stripe/react-stripe-js';
import type { FormRenderProps } from 'react-final-form';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import cleanedFilename from '~/util/cleanedFilename';
import TopSectionContainer from '../components/TopSectionContainer';
import MidSectionContainer from '../components/MidSectionContainer';
import BottomSectionContainer from '../components/BottomSectionContainer';
import Dropdown from '~/components/molecule/Dropdown';
import StripeWrapper from '~/hocs/withStripeWrapper/components/StripeWrapper';
import Catalog from '~/Catalog';
import Validation from '~/util/Validation';
import IBANElement from '~/components/atom/StripeIBANElement';
import { asPriceString } from '~/util/money';
import TEST_ID from './index.testid';
import ErrorMessage from '../components/ErrorMessage';
import useErrorReporter from '~/hooks/useErrorReporter';
import { useStartAddBillingMethodMutation } from '~/graphql/types';
import { PAYMENT_OPTIONS } from '../../../PaymentDetails/constants';
import FormUtils from '~/components/organism/FormUtils';
import Input from '~/components/molecule/Input';
import JustificationContainer from '~/components/atom/JustificationContainer';

const text = {
  startAddBillingMethodErrorMessage: Catalog.genericUnknownErrorMessageShort,
  noCustomerSecretErrorMessage: Catalog.genericUnknownErrorMessageShort,
  noPaymentIdFromStripeErrorMessage:
    'Er kan geen verbinding worden gemaakt met de betalingsserver',
  infoStart: 'Doorlopende ',
  infoType: 'SEPA-machtiging.',
  info: ' Door het verstrekken van uw rekeningnummer (IBAN), geeft u toestemming aan DatHuis B.V., gevestigd aan de Rembrandtweg 39-A, 1181 GE Amstelveen,  om overeenkomend met de opdracht doorlopend incasso-opdrachten te sturen naar uw bank om een bedrag van uw rekening af te schrijven. Als u het niet eens bent met deze afschrijving kunt u deze laten terugboeken. Neem hiervoor binnen acht weken na afschrijving contact op met uw bank. Vraag uw bank naar de voorwaarden.',
  totalPrice: 'Totaal gefactureerd ',
  nameLabel: 'Naam rekeninghouder',
  emailLabel: 'Facturatie e-mailadres',
  nextStepLabel: 'Bevestig',
  paymentOptionLabel: 'Betaalmethode',
  minimumNameLength: 'Naam moet minimaal 3 tekens bevatten',
};

type FormData = {
  name: string;
  email: string;
};
type Props = {
  onContinue: (paymentId: string) => void;
  onGoBack: (billingInfo: {
    name: string | null;
    email: string | null;
  }) => void;
  totalPriceToPay: number;
  insertionLoading: boolean;
  insertionError: string | null;
  initialEmail: string | null;
  initialName: string | null;
};
const SetupPayment: React.FCC<Props> = ({
  onContinue,
  onGoBack,
  totalPriceToPay,
  insertionLoading,
  insertionError,
  initialEmail,
  initialName,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const account = useCurrentAccount();
  const errorReporter = useErrorReporter();

  const [stripeErrorMsg, setStripeErrorMsg] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const [startAddBillingMethod, { error }] = useStartAddBillingMethodMutation();

  let errorMessage;

  if (stripeErrorMsg) {
    errorMessage = stripeErrorMsg;
  } else if (insertionError != null) {
    errorMessage = insertionError;
  } else if (error) {
    errorMessage = text.startAddBillingMethodErrorMessage;
  }

  const onFormSubmit = async args => {
    setLoading(true);
    setStripeErrorMsg(null);

    if (elements == null) {
      throw Error(
        `${cleanedFilename(__filename)} | Should not occur | elements == null`,
      );
    }
    if (stripe == null) {
      throw Error(
        `${cleanedFilename(__filename)} | Should not occur | stripe == null`,
      );
    }

    const ibanElement = elements.getElement(IbanElement);
    if (ibanElement == null) {
      throw Error(
        `${cleanedFilename(
          __filename,
        )} | Should not occur | ibanElement == null`,
      );
    }

    const result = await startAddBillingMethod({
      variables: { accountId: account.id },
    });

    const customerSecret = result?.data?.startAddBillingMethod?.customerSecret;

    if (isNil(customerSecret)) {
      errorReporter.captureException(
        new Error(`Missing customer secret`),
        'fatal',
      );
      setStripeErrorMsg(text.noCustomerSecretErrorMessage);

      setLoading(false);
      return;
    }

    return stripe
      .confirmSepaDebitSetup(customerSecret, {
        payment_method: {
          sepa_debit: ibanElement,
          billing_details: {
            name: args.name,
            email: args.email,
          },
        },
      })
      .then(result => {
        const paymentId = result?.setupIntent?.payment_method;

        if (paymentId == null) {
          const stripeError = result?.error?.message;

          errorReporter.captureException(
            new Error(
              stripeError == null
                ? text.noPaymentIdFromStripeErrorMessage
                : stripeError,
            ),
          );

          setStripeErrorMsg(
            stripeError == null
              ? text.noPaymentIdFromStripeErrorMessage
              : stripeError,
          );

          setLoading(false);
          return;
        }

        onContinue(paymentId as string);
        setLoading(false);
      });
  };

  return (
    <Container data-testid={TEST_ID.CONTAINER}>
      <Form
        validate={validate}
        onSubmit={onFormSubmit}
        initialValues={{
          name: initialName,
          email: initialEmail,
        }}
      >
        {({
          handleSubmit,
          submitting,
          pristine,
          // @ts-ignore
          values,
        }: FormRenderProps<{
          name: string | null;
          email: string | null;
        }>) => {
          const submitDisabled =
            (!pristine && (isNil(values.email) || isNil(values.name))) ||
            submitting;

          return (
            <StyledForm id="payment-info" onSubmit={handleSubmit}>
              <TopSectionContainer headerText="Betaalmethode" withBorder />
              <MidSectionContainer>
                <JustificationContainer
                  width="100%"
                  direction="column"
                  gap="base"
                >
                  <Field name="name">
                    {({ input, meta: { error, touched, submitting } }) => (
                      <Input
                        validation={[
                          (value: string) => {
                            if (value.length < 3) {
                              return text.minimumNameLength;
                            }
                            return true;
                          },
                        ]}
                        width="100%"
                        disabled={submitting || insertionLoading}
                        label={{ text: text.nameLabel }}
                        externalErrors={
                          FormUtils.showError(error, touched)
                            ? [FormUtils.showError(error, touched)]
                            : []
                        }
                        {...input}
                      />
                    )}
                  </Field>

                  <Field name="email">
                    {({ input, meta: { error, touched, submitting } }) => (
                      <Input
                        width="100%"
                        disabled={submitting || insertionLoading}
                        label={{ text: text.emailLabel }}
                        externalErrors={
                          FormUtils.showError(error, touched)
                            ? [FormUtils.showError(error, touched)]
                            : []
                        }
                        {...input}
                      />
                    )}
                  </Field>
                  <Dropdown
                    disabled={insertionLoading}
                    options={PAYMENT_OPTIONS}
                    onChange={() => {}}
                    selectedOptionIdx={0}
                    label={text.paymentOptionLabel}
                  />

                  <IBANElement
                    elements={elements}
                    currentLast4={null}
                    onChange={() => setStripeErrorMsg(null)}
                  />

                  <InfoContainer>
                    {text.infoStart}
                    <InfoType>{text.infoType}</InfoType>
                    {text.info}
                  </InfoContainer>
                </JustificationContainer>

                <RowContainer>
                  <span>{text.totalPrice}</span>
                  <span data-testid={TEST_ID.TOTAL_PRICE}>
                    {asPriceString(totalPriceToPay)}
                  </span>
                </RowContainer>
                <ErrorMessage errorMessage={errorMessage} />
              </MidSectionContainer>

              <BottomSectionContainer
                onGoBack={() => {
                  onGoBack({
                    name: values?.name,
                    email: values?.email,
                  });
                }}
                nextStepButton={text.nextStepLabel}
                loading={insertionLoading || submitting || loading}
                disabled={
                  submitDisabled || insertionLoading || submitting || loading
                }
                submitButton={true}
              />
            </StyledForm>
          );
        }}
      </Form>
    </Container>
  );
};

const validate = ({ email, name }: FormData) => {
  const errors: { email: string | undefined; name: string | undefined } = {
    email: undefined,
    name: undefined,
  };

  if (Validation.String.isEmpty(email)) {
    errors.email = Catalog.noEmail;
  } else if (!Validation.Email.isValid(email)) {
    errors.email = Catalog.invalidEmail;
  }

  if (Validation.String.isEmpty(name)) {
    errors.name = Catalog.noName;
  }

  return errors;
};

const StyledForm = styled.form<{}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
`;

const RowContainer = styled.div<{}>`
  display: flex;
  height: 70px;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  ${({ theme }) => css`
    font-weight: ${theme.fw('medium')};
    font-size: ${theme.fontSize('m')};
  `};
`;

const InfoContainer = styled.div<{}>`
  ${({ theme }) => css`
    font-size: ${theme.fontSize('s')};
    color: ${theme.color('tertiary')};
    line-height: ${theme.lineHeight('m')};
    margin-top: ${theme.space('xxxl')};
  `};
`;

const Container = styled.div<{}>`
  height: 100%;
`;

const InfoType = styled.span<{}>`
  text-decoration: underline;
`;

export default (props: Props) => (
  <StripeWrapper>
    <SetupPayment {...props} />
  </StripeWrapper>
);
