import { findLastIndex, propEq } from 'ramda';
import ELEMENTS from '~/components/organism/PluginsEditor/components/elements/elementsEnum';

const allowFocusList = [
  ELEMENTS.LINK,
  ELEMENTS.VARIABLE,
  ELEMENTS.DH_IMAGE,
  ELEMENTS.IMAGE,
  ELEMENTS.DEEP_LINK,
];

/**
 * Adds an empty {text:''} after the element to allow focusing after it
 * @param {any} children - Current children in iteration
 */
const insertSpaceAfterLastIndex = ({ children }) => {
  for (const elementType of allowFocusList) {
    const index = findLastIndex(propEq(elementType, 'type'))(children);

    if (index !== -1) {
      children.splice(index + 1, 0, '');
    }
  }
};

export default insertSpaceAfterLastIndex;
