import sanitize from 'sanitize-html';
import convertVariablesInHtml from '../convertVariablesInHtml';
import parseAndDeserialize from '../../parseAndDeserialize';
import ELEMENTS from '~/components/organism/PluginsEditor/components/elements/elementsEnum';
import {
  FlowV2_ParameterMappingFragment,
  type DeepLink_ConfigFragment,
  type DeepLinkFragment,
} from '~/graphql/types';
import { SubjectMap } from '~/components/page/Automation/v2/components/UpdateAction/components/Selector/utils/getSubject';
import { InstanceMap } from '~/components/page/Automation/v2/components/UpdateAction/components/Selector/utils/getInstance';
import { DirectoryMap } from '~/components/page/Automation/v2/components/UpdateAction/components/Selector/utils/getDirectory';
import convertFlowAttachmentsInHtml from '../convertFlowAttachmentsInHtml';
import { allowedTags } from '~/components/organism/PluginsEditor/constants';
import type { Client_FlowV2_EmailAttachment } from '~/components/page/Automation/v2/components/UpdateAction/components/types';
import type { EditorValue } from '../../../types';
import convertDeepLinksInHtml from '../../deepLinks/convertDeepLinksInHtml';

/**
 * This is used when we have html & mappings (and maybe attachments) in the editor like in Flows
 *
 */
const convertTemplateStringToSlateFragment = ({
  html,
  attachments = [],
  mappings = [],
  markAsPendingImage = false,
  subjectMap,
  instanceMap,
  directoryMap,
  customElements,
  deepLinks,
  deepLinkConfigs,
}: {
  html: string;
  mappings: Array<FlowV2_ParameterMappingFragment>;
  subjectMap: SubjectMap;
  instanceMap: InstanceMap;
  directoryMap: DirectoryMap;
  customElements?: Array<ELEMENTS>;
  attachments?: Array<Client_FlowV2_EmailAttachment>;
  markAsPendingImage?: boolean;
  deepLinks?: Array<DeepLinkFragment>;
  deepLinkConfigs?: Array<DeepLink_ConfigFragment>;
}): EditorValue => {
  const sanitized = sanitize(html, defaultSanitizeOptions);

  const convertedImages = convertFlowAttachmentsInHtml({
    str: sanitized,
    attachments,
  });

  const variablesConverted = convertVariablesInHtml({
    str: convertedImages,
    mappings,
    subjectMap,
    instanceMap,
    directoryMap,
  });

  const deepLinksConverted =
    deepLinks && deepLinkConfigs
      ? convertDeepLinksInHtml({
          str: variablesConverted,
          deepLinks,
          deepLinkConfigs,
        })
      : variablesConverted;

  const parsed = parseAndDeserialize({
    html: deepLinksConverted,
    markAsPendingImage,
    customElements: [
      ELEMENTS.VARIABLE,
      ...(customElements ? customElements : []),
    ],
  });

  return parsed;
};

export const defaultSanitizeOptions = {
  // TODO Editor: Filter out the attrs starting with 'on' because they might be dangerous
  allowedAttributes: false,
  enforceHtmlBoundary: true,
  allowedTags,
};

export default convertTemplateStringToSlateFragment;
