import {} from 'react';
import {
  useGetDeepLinkConfigsQuery,
  type DeepLink_ConfigFragment,
} from '~/graphql/types';
import useCurrentAccount from '../useCurrentAccount';
import type { OptionOf } from '~/components/molecule/Dropdown';

/**
 * Hook to use deep link configs around the app
 */
const useDeepLinkConfigs = (): {
  /** Loading state of the query */
  loading: boolean;

  /** Deep link configs as dropdown options */
  configOptions: Array<OptionOf<DeepLink_ConfigFragment>>;

  /** Used to find corresponding values for a deep link */
  deepLinkConfigs: Array<DeepLink_ConfigFragment>;
} => {
  const { id: accountId } = useCurrentAccount();
  const { data, loading } = useGetDeepLinkConfigsQuery({
    variables: { accountId },
  });

  if (!data) return { loading, configOptions: [], deepLinkConfigs: [] };

  const configOptions = data?.getDeepLinkConfigs.reduce(
    (prev, configItem) => {
      if (!configItem) return prev;

      prev?.push({
        // This makes a config unique
        key: `${configItem.type}${configItem.typeId}`,
        label: configItem.name,
        payload: configItem,
      });
      return prev;
    },
    [] as Array<OptionOf<DeepLink_ConfigFragment>>,
  );

  return {
    loading,
    configOptions,
    deepLinkConfigs: data.getDeepLinkConfigs || [],
  };
};

export default useDeepLinkConfigs;
