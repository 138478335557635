/**
 *
 * Collection of validation functions
 */

import Email from './Email';
import Phone from './Phone';
import Postcode from './Postcode';
import Password from './Password';
import String from './String';
import Number from './Number';
import URL from './URL';
import Tag from './Tag';
import ContactId from './ContactId';
import DeepLinkId from './DeepLinkId';

export default {
  Email,
  ContactId,
  DeepLinkId,
  Phone,
  Postcode,
  Password,
  String,
  Number,
  URL,
  Tag,
};
