import React from 'react';
import {
  blocksMap,
  DND_SOURCES,
  AREA_HEIGHT,
  type ScreenNodeBlock_FE,
} from '../../constants';
import JustificationContainer from '~/components/atom/JustificationContainer';
import DraggableItem from '~/components/atom/DraggableItem';
import { Heading4, Variant, Body } from '~/components/atom/Typography';
import BlockComponentTile from './components/BlockComponentTile';
import useCurrentBreakpoint from '~/hooks/useCurrentBreakpoint';
import useBlockOptions from '~/components/page/Forms/components/Builder/hooks/useBlockOptions';

const text = {
  description: 'Selecteer het blok en sleep het naar het midden',
  formComp: 'Formulier invoer',
  uiComp: 'Formulier opmaken',
  contactComp: 'Contactgegevens',
  disabledReason: 'Je kunt dit blok maar één keer gebruiken',
};

type Props = { id: string };

const ComponentsCollectionArea: React.FC<Props> = ({ id }) => {
  const isBigScreen = useCurrentBreakpoint() === 'desktopBig';
  const { components } = useBlockOptions({ nodeId: id });

  return (
    <JustificationContainer
      direction="column"
      gap="m"
      style={{
        height: AREA_HEIGHT,
        overflowY: 'scroll',
        maxWidth: isBigScreen ? '380px' : '243px',
      }}
      backgroundColor={{ group: 'primary', variant: 'translucent' }}
      padding={['base']}
      border={{ radius: 'base' }}
      width="100%"
    >
      <Body margin={[null]} size="base">
        {text.description}
      </Body>
      {components.contactOptions.length > 0 && (
        <JustificationContainer direction="column">
          <Heading4 variant={Variant.primary}>{text.contactComp}</Heading4>
          <JustificationContainer wrap="wrap" gap="base">
            {components.contactOptions.map(
              ({ payload, label, type }, index) => (
                <ItemFacade
                  key={payload}
                  payload={payload}
                  label={label}
                  index={index}
                  disabled={
                    type && type === 'DISABLED'
                      ? { reason: text.disabledReason }
                      : undefined
                  }
                />
              ),
            )}
          </JustificationContainer>
        </JustificationContainer>
      )}
      {components.formOptions.length > 0 && (
        <JustificationContainer direction="column">
          <Heading4 variant={Variant.primary}>{text.formComp}</Heading4>
          <JustificationContainer wrap="wrap" gap="base">
            {components.formOptions.map(({ payload, type, label }, index) => (
              <ItemFacade
                key={payload}
                payload={payload}
                label={label}
                index={index}
                disabled={
                  type && type === 'DISABLED'
                    ? { reason: text.disabledReason }
                    : undefined
                }
              />
            ))}
          </JustificationContainer>
        </JustificationContainer>
      )}
      <JustificationContainer direction="column">
        <Heading4 variant={Variant.primary}>{text.uiComp}</Heading4>
        <JustificationContainer wrap="wrap" gap="base">
          {components.uiOptions.map(({ type, payload, label }, index) => (
            <ItemFacade
              key={payload}
              payload={payload}
              label={label}
              index={index}
              disabled={
                type && type === 'DISABLED'
                  ? { reason: text.disabledReason }
                  : undefined
              }
            />
          ))}
        </JustificationContainer>
      </JustificationContainer>
    </JustificationContainer>
  );
};

type FacadeProps = {
  index: number;
  label: React.ReactNode;
  payload: ScreenNodeBlock_FE;
  disabled?: { reason: string };
};

const ItemFacade: React.FC<FacadeProps> = ({
  payload,
  label,
  index,
  disabled,
}) => {
  if (disabled) {
    return (
      <BlockComponentTile
        label={label}
        icon={blocksMap[payload].icon}
        color={blocksMap[payload].color}
        disabled={disabled}
      />
    );
  }

  return (
    <DraggableItem
      key={blocksMap[payload].label.nl}
      itemId={payload}
      index={index}
      source={DND_SOURCES.componentsCollection}
    >
      <BlockComponentTile
        label={label}
        icon={blocksMap[payload].icon}
        color={blocksMap[payload].color}
      />
    </DraggableItem>
  );
};

export default ComponentsCollectionArea;
