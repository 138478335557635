import { EmailSignature, FromAddress } from '~/graphql/types';
import TEST_ID from './index.testid';
import {
  BLOCK_ALIGN_BUTTONS,
  FONT_BASE_BUTTONS,
  FONT_STYLE_BUTTONS,
  LIST_BUTTONS,
  MISC_BUTTONS,
  TOOLBAR_DIVIDER,
} from '~/components/organism/PluginsEditor/components/StaticToolbar/components/Buttons/buttonDefinitions';
import type { ToolbarComponent } from '~/components/organism/PluginsEditor/components/StaticToolbar/components/Buttons';

const text = {
  emailTypeLabel: 'Type',
  explanation: 'E-mail versturen',
  subjectLabel: 'Onderwerp',
  addAttachmentButtonLabel: 'uit een stap',
  selectAttachmentPointerStepLabel: 'Selecteer stap',
  selectAttachmentPointerFieldLabel: 'Selecteer bijlage',
  attachmentsHeader: 'Bijlagen toevoegen',
  marketingLabel: 'Marketing',
  transactionalLabel: 'Transactioneel',
  needsUnsubscribeLinkText:
    'Bij marketing e-mails is het verplicht om een uitschrijflink toe te voegen. Voeg een uitschrijflink toe met de button in de toolbar.',
  isOverMaxEmailSizeErrorMessage:
    'De bijlagen zijn te groot, een e-mail mag maximaal 20MB aan bijlagen bevatten',
  fromClosestEntity: 'Eigenaar van het contact',
  signatureOptions: 'Handtekening',
  noSignature: 'Geen handtekening',
  fromAddressSignatureLabel: 'Hetzelfde als vanaf adres',
  closestEntitySignatureLabel: 'Eigenaar van het contact',
  warningMessage:
    'Zorg ervoor dat alle gebruikers een handtekening hebben ingesteld als je deze optie selecteert',
};

export type AddressOptions = Array<{
  key: FromAddress | string;
  label: string;
  payload: FromAddress | null;
}>;

export const fromAddressOptions = (emailText?: string): AddressOptions => [
  {
    key: FromAddress.ClosestToContact,
    label: text.fromClosestEntity,
    payload: FromAddress.ClosestToContact,
  },
  {
    key: 'Account',
    label: `Account ${emailText}`,
    payload: null,
  },
];

export type SignatureOptions = Array<{
  key: string;
  label: string;
  payload: EmailSignature | null;
}>;

export const signatureOptions: SignatureOptions = [
  {
    key: 'no-signature',
    label: text.noSignature,
    payload: null,
  },
  {
    key: EmailSignature.FromAddressEntity as string,
    label: text.fromAddressSignatureLabel,
    payload: EmailSignature.FromAddressEntity,
  },
  {
    key: EmailSignature.ClosestEntity as string,
    label: text.closestEntitySignatureLabel,
    payload: EmailSignature.ClosestEntity,
  },
];

export type TransactionalOptions = Array<{
  label: string;
  value: boolean;
  dataTestid: string;
}>;

export const transactionalOptions: TransactionalOptions = [
  {
    label: text.marketingLabel,
    value: false,
    dataTestid: TEST_ID.MARKETING_OPTION,
  },
  {
    label: text.transactionalLabel,
    value: true,
    dataTestid: TEST_ID.TRANSACTIONAL_OPTION,
  },
];

/**
 * This pragma is appended to the HTML to distinguish between HTML set by the DHEditor and HTML set by the user.
 */
export const CUSTOM_HTML_PRAGMA = '<!-- DH_CUSTOM_HTML -->';

export const EMAIL_EDITOR_BUTTONS: Array<ToolbarComponent> = [
  ...FONT_BASE_BUTTONS,
  TOOLBAR_DIVIDER,
  ...LIST_BUTTONS,
  TOOLBAR_DIVIDER,
  ...BLOCK_ALIGN_BUTTONS,
  TOOLBAR_DIVIDER,
  ...FONT_STYLE_BUTTONS,
  TOOLBAR_DIVIDER,
  { type: 'button', name: 'image' },
  { type: 'button', name: 'link' },
  { type: 'button', name: 'insert-html' },
  { type: 'button', name: 'blockquote' },
  { type: 'button', name: 'variable' },
  { type: 'button', name: 'deep-link' },
  TOOLBAR_DIVIDER,
  ...MISC_BUTTONS,
];
