import type React from 'react';
import type { CropperRef, FixedCropperRef } from 'react-advanced-cropper';

export const MAX_SERVED_SIZE_IN_PX = 2400;

// Utility function to check WebP support
const isWebPSupported = (): Promise<boolean> =>
  new Promise(resolve => {
    const webpTestImg = new Image();
    webpTestImg.src =
      'data:image/webp;base64,UklGRiIAAABXRUJQVlA4WAoAAAAQAAAABwAIAwAASUNDUMgBAAAAPbAEAAkAAAAD9EAEAAAAwA0AAZAA';
    webpTestImg.onload = () => resolve(true);
    webpTestImg.onerror = () => resolve(false);
  });

/**
 * Function to get the data URL from the cropper and convert to WebP if supported
 * @param cropperRef - The cropper reference to get the canvas element
 * @param maxSizeInBytes - Maximum file size that we want to enforce
 * @param maxWidth - Maximum width of image we want to enforce
 * @param initialQuality - Used to gracefully scale down the quality of an image until the maxSizeInBytes is satisfied
 * @param minQuality - How far are we allowed to reduce the quality of the image?
 * @returns string - A base64 data URL holding the image data
 */
const getDataUrlWithMaxFileSize = async (
  cropperRef: React.RefObject<CropperRef> | React.RefObject<FixedCropperRef>,
  maxSizeInBytes: number,
  maxWidth = MAX_SERVED_SIZE_IN_PX,
  initialQuality = 0.9,
  minQuality = 0.3,
): Promise<string | null> => {
  const supportsWebP = await isWebPSupported();

  return new Promise((resolve, reject) => {
    if (!cropperRef.current) {
      reject('Cropper reference is null or undefined');
      return;
    }

    const originalCanvas = cropperRef.current.getCanvas({ maxWidth });
    if (!originalCanvas) {
      reject('Failed to get canvas from cropper');
      return;
    }

    // Check if a canvas element already exists on the page
    const existingCanvas = document.querySelector('canvas');
    const canvasToUse = existingCanvas || document.createElement('canvas');
    const context = canvasToUse.getContext('2d');

    canvasToUse.width = originalCanvas.width;
    canvasToUse.height = originalCanvas.height;

    if (context) {
      // Handle background for non-transparent formats (JPEG)
      if (!supportsWebP && !originalCanvas.toDataURL().includes('image/png')) {
        context.fillStyle = 'white';
        context.fillRect(0, 0, canvasToUse.width, canvasToUse.height);
      }

      // Draw the original image onto the canvas
      context.drawImage(originalCanvas, 0, 0);
    }

    const exportFormat = supportsWebP
      ? 'image/webp'
      : originalCanvas.toDataURL().includes('image/png')
        ? 'image/png'
        : 'image/jpeg';

    let quality = initialQuality;
    let dataUrl = canvasToUse.toDataURL(exportFormat, quality);

    if (!dataUrl) {
      reject('Failed to generate data URL');
      return;
    }

    // Function to calculate size from base64 data URL
    const dataUrlToSize = (dataUrl: string) => {
      const base64StringLength =
        dataUrl.length - `data:${exportFormat};base64,`.length;
      return (base64StringLength * 3) / 4;
    };

    let size = dataUrlToSize(dataUrl);

    // Reduce quality until the file size meets the limit
    while (size > maxSizeInBytes && quality > minQuality) {
      quality -= 0.1; // Reduce quality in steps
      dataUrl = canvasToUse.toDataURL(exportFormat, quality);
      size = dataUrlToSize(dataUrl);

      if (!dataUrl) {
        reject('Failed to generate data URL');
        return;
      }
    }

    if (size > maxSizeInBytes) {
      reject('Unable to generate data URL under the required file size limit.');
    } else {
      resolve(dataUrl);
    }
  });
};

export default getDataUrlWithMaxFileSize;
