import React, { useState } from 'react';
import JustificationContainer from '~/components/atom/JustificationContainer';
import { UserRole } from '~/graphql/types';
import Drawer from '~/components/organism/Drawer';
import Dropdown, { type OptionOf } from '~/components/molecule/Dropdown';
import Button from '~/components/atom/Button';
import useUserOptions from '~/hooks/useUserOptions';
import type { ExpandedUser } from '~/hooks/useUsers';

const text = {
  title: 'Administrator toevoegen',
  description: 'Voeg een gebruiker toe als administrator voor dit account.',
  buttonTitle: 'Toevoegen',
  selectUser: 'Selecteer een gebruiker',
};

type SelectedUserType = {
  selectedOptionIdx: number;
  id: string;
  label: string;
};

type DropdownHandler = {
  selectedOptionIdx: number;
  option: OptionOf<ExpandedUser>;
};

type Props = {
  updateUserRole: (arg0: { role: UserRole; userId: string }) => void;
  handleToggleDrawer: (show: boolean) => void;
  isOpen: boolean;
  loading: boolean;
};

const AccountDrawer: React.FC<Props> = ({
  isOpen,
  loading,
  updateUserRole,
  handleToggleDrawer,
}) => {
  const [selectedUser, setSelectedUser] = useState<SelectedUserType | null>(
    null,
  );
  const [error, setError] = useState<string | null>(null);

  const selectedOptionIdx = selectedUser
    ? selectedUser.selectedOptionIdx
    : null;

  const nonAdminUsers = useUserOptions().filter(
    user => user.payload?.role === UserRole.User,
  );

  const handleChangeOption = ({
    selectedOptionIdx,
    option,
  }: DropdownHandler) => {
    setSelectedUser({
      selectedOptionIdx,
      id: option.payload.id,
      label: option.label as string,
    });
    setError(null);
  };

  const saveAdmin = () => {
    if (selectedUser) {
      updateUserRole({
        role: UserRole.Admin,
        userId: selectedUser.id,
      });
      handleCloseDrawer();
    } else {
      setError(text.selectUser);
    }
  };

  const handleCloseDrawer = () => {
    setSelectedUser(null);
    setError(null);
    handleToggleDrawer(false);
  };

  return (
    <Drawer active={isOpen} hideSidebar={handleCloseDrawer} title={text.title}>
      <p>{text.description}</p>
      <JustificationContainer
        width="100%"
        align="center"
        margin={[null, null, 'm', null]}
      >
        <Dropdown
          dataTestId="admins-dropdown"
          selectedOptionIdx={selectedOptionIdx}
          options={nonAdminUsers}
          disabled={nonAdminUsers.length === 0}
          onChange={handleChangeOption}
          error={error || null}
          placeholder={text.selectUser}
        />
      </JustificationContainer>
      <Button
        size="medium"
        loading={loading}
        disabled={loading}
        dataTestId="save-admin-button"
        onClick={saveAdmin}
        label={text.buttonTitle}
      />
    </Drawer>
  );
};

export default AccountDrawer;
