import type { WindowLocation } from '@gatsbyjs/reach-router';
import type { Credentials, WindowState } from '../..';

/**
 * Extracts the email and password parameters from the url:
 * https://devsfa.dathuis.nl/login?email=user@dathuis.nl&password=Test123.
 *
 * http://localhost:8001/login/?email=user@dathuis.nl&password=12345678
 *
 * Using the "magic link" our users can login without needing to enter their email and password.
 * We send them this link within an email when a new user has signed up etc.
 *
 * Note: We use custom parameter extraction here instead of URLSearchParams because
 * the parameters in our magic links are not URL-encoded. This allows us to preserve
 * the original values exactly as they appear in the URL.
 *
 * @param {WindowLocation<WindowState>} location - Window location with the added state
 * keywords: loginmagiclinkm, magiclink
 */
const getEmailAndPasswordFromMagicLink = (
  location: WindowLocation<WindowState>,
): Credentials => {
  // Remove the leading '?' if present
  const search = location.search.startsWith('?')
    ? location.search.slice(1)
    : location.search;

  let email: string | null = null;
  let password: string | null = null;

  // Match email parameter using regex:
  // - [^&]* matches any characters except '&'
  // - (?:&[^&]*)* allows for '&' characters within the email (except &password)
  // - (?:&password|$) ensures we stop at either '&password' or end of string
  const emailMatch = search.match(/email=([^&]*(?:&[^&]*)*?)(?:&password|$)/);
  if (emailMatch) {
    email = emailMatch[1];
  }

  // Match password parameter:
  // - (.*) captures everything after 'password=' until the end of string
  // This allows passwords to contain any character, including '&' symbols
  const passwordMatch = search.match(/password=(.*)/);
  if (passwordMatch) {
    password = passwordMatch[1];
  }

  return {
    email,
    password,
  };
};

export default getEmailAndPasswordFromMagicLink;
