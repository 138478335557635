import { RenderElementProps } from 'slate-react';
import Image from './Image';
import Div from './DIVelement';
import BlockQuote from './BlockQuote';
import OrderedList from './OrderedList';
import UnorderedList from './UnorderedList';
import ListItem from './ListItem';
import LinkElement from './LinkElement';
import { CustomElement } from '../../types';
import Span from './Span';
import GenericHtmlElement from './GenericHtmlElement';
import VariableElement from './VariableElement';
import Paragraph from './Paragraph';
import ELEMENTS from './elementsEnum';
import SignatureElement from './SignatureElement';
import ImmutableHtmlElement from './ImmutableHtmlElement';
import HeadingOne from './HeadingOne';
import HeadingTwo from './HeadingTwo';
import HeadingThree from './HeadingThree';
import HeadingFour from './HeadingFour';
import HeadingFive from './HeadingFive';
import HeadingSix from './HeadingSix';
import DeepLinkElement from './DeepLinkElement';

type ElementProps = {
  renderComponent: (props: RenderElementProps) => JSX.Element;
  nodeName?: string;
  serialize: (node?: CustomElement, children?: any) => string;
  deserialize?: (el?: Node) => void;
};

type ElementsObj = { [key in ELEMENTS]?: ElementProps };

const BASE_ELEMENETS: ElementsObj = {
  [ELEMENTS.BLOCKQUOTE]: BlockQuote,
  [ELEMENTS.DIV]: Div,
  [ELEMENTS.PARAGRAPH]: Paragraph,
  [ELEMENTS.GENERIC_HTML_ELEMENT]: GenericHtmlElement,
  [ELEMENTS.IMMUTABLE_HTML_ELEMENT]: ImmutableHtmlElement,
  [ELEMENTS.LI]: ListItem,
  [ELEMENTS.LINK]: LinkElement,
  [ELEMENTS.OL]: OrderedList,
  [ELEMENTS.UL]: UnorderedList,
  [ELEMENTS.SPAN]: Span,
};

const CUSTOM_ELEMENTS: ElementsObj = {
  [ELEMENTS.VARIABLE]: VariableElement,
  [ELEMENTS.DEEP_LINK]: DeepLinkElement,
  [ELEMENTS.IMAGE]: Image,
  [ELEMENTS.DH_IMAGE]: Image,
  [ELEMENTS.SIGNATURE]: SignatureElement,
  [ELEMENTS.H1]: HeadingOne,
  [ELEMENTS.H2]: HeadingTwo,
  [ELEMENTS.H3]: HeadingThree,
  [ELEMENTS.H4]: HeadingFour,
  [ELEMENTS.H5]: HeadingFive,
  [ELEMENTS.H6]: HeadingSix,
};

/**
 * To make sure that customElements are rendered inside the editor, make sure to pass
 * them to convertHtmlToSlateFragment
 *
 * If you are adding a custom node name like <dhvariable>, <dhsignature> make sure to add
 * them to the allow list in `src/components/PluginsEditor/constants.ts`. Otherwise they
 * might be removed when sanitizing the html
 */
const getElements = (customElements: Array<ELEMENTS> = []): ElementsObj => {
  const elements = { ...BASE_ELEMENETS };
  customElements.forEach(elName => {
    if (CUSTOM_ELEMENTS[elName]) {
      elements[elName] = CUSTOM_ELEMENTS[elName];
    }
  });
  return elements;
};

export default getElements;
