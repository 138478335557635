import { Transforms } from 'slate';
import { ReactEditor } from 'slate-react';
import ELEMENTS from '~/components/organism/PluginsEditor/components/elements/elementsEnum';
import {
  DHEditor,
  LinkElement,
} from '~/components/organism/PluginsEditor/types';

export const insertLink = (editor: DHEditor, url: string, text: string) => {
  const linkEl: LinkElement = {
    type: ELEMENTS.LINK,
    url,
    children: [{ text }],
  };

  if (!text) {
    Transforms.wrapNodes(editor, linkEl);
    return;
  }

  // Use insertNodes when element does not span over multiple lines
  if (editor.selection?.anchor.path[0] === editor.selection?.focus.path[0]) {
    Transforms.insertNodes(editor, linkEl);
  } else {
    // Wrap nodes when multiple lines are selected
    Transforms.wrapNodes(editor, linkEl, {
      mode: 'lowest',
      split: true,
    });
  }

  // Remove edit link toolbar after insertion
  Transforms.move(editor, {
    distance: 1,
    unit: 'offset',
  });
  ReactEditor.blur(editor);
};
