import { atom, selector } from 'recoil';
import flowActions from '.';
import getDeepLinksMap from '../components/Builder/utils/getDeepLinksMap';
import type { DeepLink_ConfigFragment } from '~/graphql/types';

export type DeepLinksMap = {
  [id: string]: {
    label: string;
  };
};

export const deepLinkConfigsState = atom<Array<DeepLink_ConfigFragment>>({
  key: 'deepLinkConfigsState',
  default: [],
});

export const deepLinks = selector<DeepLinksMap>({
  key: 'currentDeepLinks',
  get: ({ get }) => {
    const deepLinkConfigs = get(deepLinkConfigsState);
    const actions = get(flowActions);

    if (deepLinkConfigs) {
      const value = getDeepLinksMap({ deepLinkConfigs, actions });
      return value;
    }

    return {};
  },
});
